import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyListPageCtn: {
      background: '#F3F3F3',
    },

    container: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      width: '100%',
      padding: 0,
    },

    wrapper: {
      width: '100%',
      padding: '16px 0',
    },

    title: {
      paddingBottom: theme.spacing(4),
    },

    propertiesSearchFormCtn: {
      padding: theme.spacing(2.5, 2),
      backgroundColor: '#fff',
      boxShadow: '0 0 40px rgba(0,0,0,0.11)',
    },

    propertiesCtn: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
    },

    row: {
      margin: '0 -10px',
      width: 'auto',
    },

    p10: {
      padding: ' 0 10px',
    },

    pt100: {
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },

    showDesktop: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    [theme.breakpoints.down('sm')]: {
      row: {
        margin: 0,
      },
    },

    ads: {
      display: 'flex',
      gap: 30,
      flexDirection: 'column',
      flex: 1,
    },

    adsItem: {
      flex: 1,
      '& > div': {
        position: 'sticky',
        top: 100,
      },
    },

    gridItem: {
      display: 'flex',
    },
  })
);

export default useStyles;
