import * as React from 'react';
import { Box, Button, Card, CardActionArea } from '@material-ui/core';
import { Link, Typography } from '../../../atoms';
import { navigate } from '../../../../utils/dom';
import { formatMoney } from '../../../../utils/number';
import {
  ImageData,
  PropertyV2,
  VideoData,
} from '../../../../api/property/types';
import useStyles from './PropertyCard.styles';
import {
  getAddressFromPropertyData,
  getNearbyPlacesByPlaceType,
  getNearbyPlaceStations,
} from '../../../../utils/address';
import { PropertyListMobileViewMode } from '../PropertyListPage';
import PriceText from './PriceText';
import AddressText from './AddressText';
import { getPropertyAttributes } from '../../../../utils/property';
import PropertyTag from './PropertyTag';
import NearbyPlaces from './NearbyPlaces';
import PropertyAttributeIcon from '../single-view/Common/PropertyAttributeIcon';
import { getPropertyAttributeLabel } from '../single-view/Common/propertyAttributes';
import usePalette from '../../../../styles/usePalette';
import FlatPrices from './FlatPrices';
import useScreenType from '../../../../utils/dom/useScreenType';
import { ScreenType } from '../../../../config';
import useFetchPropertyImages from '../../../../api/property/useFetchPropertyImages';
import useFetchPropertyVideos from '../../../../api/property/useFetchPropertyVideos';
import { swapArrayItem } from '../../../../utils/array';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { getMediaEntries } from '../single-view/utils';
import TrainIcon from './TrainIcon';
import getFlatPrices from '../../../../utils/property/getFlatPrices';

export interface PropertyCardProps {
  property: PropertyV2;
  propertyListMobileViewMode: PropertyListMobileViewMode;
  screenType?: ScreenType;
}

const ATTRIBUTE_TAGS_COUNT = {
  default: 5,
  mobile: 3,
};

export default function PropertyCard({
  property,
  propertyListMobileViewMode,
  screenType = useScreenType(),
}: PropertyCardProps) {
  const classes = useStyles({
    propertyListMobileViewMode,
    screenType,
  });
  const palette = usePalette();

  const { data: propertyImagesResponse } = useFetchPropertyImages({
    developmentId: property.development_id,
    queryConfig: {
      staleTime: Infinity,
    },
  });

  const { data: propertyVideosResponse } = useFetchPropertyVideos({
    developmentId: property.development_id,
    queryConfig: {
      staleTime: Infinity,
    },
  });

  let developmentImages = propertyImagesResponse?.data?.developmentImages || [];
  const developmentVideos =
    propertyVideosResponse?.data?.developmentVideos || [];

  const flatImages =
    propertyImagesResponse?.data?.flatImages?.reduce(
      (allFlatImages, flatImages) => {
        return [...allFlatImages, ...flatImages];
      },
      []
    ) || [];

  const flatVideos =
    propertyVideosResponse?.data?.flatVideos?.reduce(
      (allFlatVideos, flatVideos) => {
        return [...allFlatVideos, ...flatVideos];
      },
      []
    ) || [];

  // swap array item development images
  developmentImages.map((imgDevelopment, index) => {
    if (
      property.img_thumbnail_id &&
      imgDevelopment.orgimages_id === property.img_thumbnail_id
    ) {
      developmentImages = swapArrayItem(developmentImages, 0, index);
    }
  });

  const propertyImages: ImageData[] =
    developmentImages && flatImages
      ? [...developmentImages, ...flatImages]
      : [];

  const propertyVideos: VideoData[] =
    developmentVideos && flatVideos
      ? [...developmentVideos, ...flatVideos]
      : [];

  const originalMediaEntries = getMediaEntries(propertyImages, propertyVideos);
  const imgSrcs = originalMediaEntries.map(([, imageData]) => imageData);

  const handleCardClicked = () => {
    void navigate(`/properties/${property.development_id}`);
  };

  const propertyAttributes = getPropertyAttributes(property, {
    whatToInclude: 'all',
    limit: 10,
  });

  const nearbyPlacesByPlaceType = getNearbyPlacesByPlaceType(
    property.nearby_places ?? []
  );

  const isMobile = screenType === ScreenType.MOBILE;

  const nearbyPlaceCount = isMobile ? 2 : 3;
  const nearbyPlaceStations = getNearbyPlaceStations(
    nearbyPlacesByPlaceType,
    nearbyPlaceCount
  );

  const flatPrices = getFlatPrices(property);

  const minFlatPrice = property.rent_pcm;

  return (
    <Box className={classes.mainCtn}>
      <Card className={classes.card}>
        {/* PROPERTY PHOTO (THUMBNAIL) */}

        <Box className={classes.propertyPhotoSection}>
          <Box width="100%" height="100%" position="absolute">
            <ImageCarousel
              justAdded={false}
              imgSrcs={imgSrcs}
              imgHeight={'100%'}
              mobileViewMode={propertyListMobileViewMode}
              onImageClick={handleCardClicked}
            />
          </Box>
        </Box>

        {/* INFORMATION SECTION */}

        <Link
          to={`/properties/${property.development_id}`}
          noColor
          className={classes.safariLinkFix}
        >
          <CardActionArea
            className={classes.infoSection}
            onClick={handleCardClicked}
          >
            <Box className={classes.infoSectionHeader}>
              {/* Name and price */}

              <Box
                className={classes.infoSectionNameAndPrice}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" component="h1" fontWeight="bold">
                  {property.title}
                </Typography>
                <PriceText
                  formattedPrice={formatMoney(minFlatPrice, 'GBP', 0)}
                />
              </Box>
            </Box>

            <Box className={classes.infoSectionLeftColumn}>
              {/* Address */}

              <AddressText addressText={getAddressFromPropertyData(property)} />

              {/* Property tags */}

              <Box className={classes.infoSectionPropertyTags}>
                {propertyAttributes
                  .slice(
                    0,
                    isMobile
                      ? ATTRIBUTE_TAGS_COUNT.mobile
                      : ATTRIBUTE_TAGS_COUNT.default
                  )
                  .map((attribute) => (
                    <PropertyTag
                      key={attribute}
                      text={getPropertyAttributeLabel(attribute, property)}
                    />
                  ))}
              </Box>

              {/* Property description */}

              {!isMobile && (
                <Typography variant="subtitle1" color="grey" colorVariant={600}>
                  {property.description_free_text ??
                    'This property has no description.'}
                </Typography>
              )}

              {/* Top attributes */}

              {!isMobile && (
                <Box className={classes.infoSectionPropertyAttributeIcons}>
                  {propertyAttributes.map((attribute) => (
                    <PropertyAttributeIcon
                      key={attribute}
                      attribute={attribute}
                      stroke={palette.grey['600']}
                    />
                  ))}
                </Box>
              )}

              {/* Distance to nearby places */}

              <Box className={classes.infoSectionNearbyPlaces}>
                {nearbyPlaceStations.map((nearbyPlace) => (
                  <NearbyPlaces
                    key={nearbyPlace.place_id}
                    places={[nearbyPlace]}
                    icon={TrainIcon}
                  />
                ))}
              </Box>
            </Box>

            {!isMobile && (
              <Box className={classes.infoSectionRightColumn}>
                {/* Flat rental prices */}

                <FlatPrices flatPrices={flatPrices} />

                {/* View properties button */}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCardClicked}
                >
                  View properties
                </Button>
              </Box>
            )}
          </CardActionArea>
        </Link>
      </Card>
    </Box>
  );
}
