import { Box, Container, Typography } from '@material-ui/core';
import * as React from 'react';
import SpecialBoxContact from './SpecialBoxContact';
import useStyles from './styles';

function TalkToUs() {
  const classes = useStyles();
  return (
    <Box p="50px 15px 65px 15px" width="100%">
      <Container className={classes.container}>
        <Box pb={3}>
          <Typography variant="h4" className={classes.whyTitle}>
            Talk to us
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignContent="stretch"
          className={classes.row}
        >
          <a
            href="mailto:info@buildtorent.io"
            className={classes.decorationNone}
          >
            <SpecialBoxContact comment="Email" />
          </a>
        </Box>
      </Container>
    </Box>
  );
}

export default TalkToUs;
