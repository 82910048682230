import * as React from 'react';
import { Box } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Button, Typography } from '../../atoms';
import SearchBar, { SearchBarProps } from '../SearchBar';
import {
  bedroomsValues,
  bedSizeValues,
  billsIncludedValues,
  breakClauseValues,
  defaultRentRangeStep,
  defaultRentRangeValues,
  depositTermValues,
  Dropdown,
  furnishingValues,
  getHeroSearchRadiusValues,
  getMoreFilterItemsPrimitiveAttributesBoolean,
  getSearchRadiusValues,
  moreDropdownFiltered,
  hobTypeValues,
  noticePeriodValues,
} from './config';
import FilterButtonWithDropdown from './components/FilterButtonWithDropdown/FilterButtonWithDropdown';
import DropdownPopper from './components/DropdownPopper/DropdownPopper';
import RadiosFilter from './components/RadiosFilter/RadiosFilter';
import MultiStringFilter from './components/MultiStringFilter/MultiStringFilter';
import SliderFilter from './components/SliderFilter/SliderFilter';
import BooleanFilter from './components/BooleanFilter/BooleanFilter';
import {
  PropertiesPrimitiveAttributesFilters,
  SetFilters,
  SetPaginationProperty,
  SetSortType,
  ValidationFn,
} from './types';
import useStyles from './PropertiesSearchForm.styles';
import { DevelopmentBaseFilter } from '../../../api/property/useProperties/types';
import SortByRecommendedButton from './components/SortByRecommendedButton/SortByRecommendedButton';
import { PropertiesSearchSortType } from '../../../api/property/types';
import { PaginationProperties } from '../../../api/utils/types';
import SortByPriceButton from './components/SortByPriceButton/SortByPriceButton';
import ViewSwitchButton from './components/ViewSwitchButton/ViewSwitchButton';
import { PropertyListMobileViewMode } from '../../pages/properties/PropertyListPage';
import SelectFilter from './components/SelectFilter/SelectFilter';
import NumberFilter from './components/NumberFilter/NumberFilter';
import useScreenType from '../../../utils/dom/useScreenType';
import { ScreenType } from '../../../config';

export interface PropertiesSearchFormProps {
  searchBarProps: SearchBarProps;

  propertiesFilters: DevelopmentBaseFilter;
  propertiesPrimitiveAttributesFilters: PropertiesPrimitiveAttributesFilters;
  sortType: PropertiesSearchSortType;
  paginationProperties: PaginationProperties;
  setPropertiesFilter: SetFilters;
  setPropertiesPrimitiveAttributesFilters: SetFilters;
  setSortType: SetSortType;
  setPaginationProperty: SetPaginationProperty;
  resetFilters: () => void;

  showSortRecommendedBtn?: boolean;
  showSortPriceBtn?: boolean;

  showSearchRadiusFilter?: boolean;
  showBedroomsFilter?: boolean;
  showPriceFilter?: boolean;
  showFurnishingFilter?: boolean;
  showPetFriendlyFilter?: boolean;
  showZeroDepositFilter?: boolean;
  showMoreFilter?: boolean;

  viewMode?: PropertyListMobileViewMode;
  setViewMode?: (newViewMode: PropertyListMobileViewMode) => void;
  showViewSwitch?: boolean;

  isHeroSearch?: boolean;
}

function MoreCardContainer({
  onCancel,
  onReset,
  children,
}: {
  onCancel: () => void;
  onReset: () => void;
  children?: React.ReactNode;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.moreCardCtn}>
      {children}
      <Box className={classes.moreCardControlPanelCtn}>
        <Button variant="outlined" onClick={onReset}>
          <Refresh color="inherit" fontSize="small" />
          <span className={classes.resetButtonTextPortion}>Clear</span>
        </Button>
        <Button color="primary" variant="contained" onClick={onCancel}>
          Close
        </Button>
      </Box>
    </Box>
  );
}

function FilterGroup({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.filterGroup}>
      <Typography fontWeight="bold">{title}</Typography>
      <Box className={classes.filterGroupItems}>{children}</Box>
    </Box>
  );
}

function BooleanFilterGroup({
  title,
  items,
}: {
  title: string;
  items: Array<{
    label: string;
    value: boolean | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }>;
}) {
  return (
    <FilterGroup title={title}>
      {items.map(({ label, value, onChange }) => (
        <BooleanFilter
          key={label}
          value={value}
          label={label}
          onChange={onChange}
        />
      ))}
    </FilterGroup>
  );
}

export default function PropertiesSearchForm({
  searchBarProps,
  propertiesFilters,
  setPropertiesFilter,
  propertiesPrimitiveAttributesFilters,
  setPropertiesPrimitiveAttributesFilters,
  resetFilters,
  sortType,
  setSortType,
  showSortRecommendedBtn,
  showSortPriceBtn,
  showSearchRadiusFilter,
  showBedroomsFilter,
  showPriceFilter,
  showFurnishingFilter,
  showPetFriendlyFilter,
  showZeroDepositFilter,
  showMoreFilter,
  viewMode,
  setViewMode,
  showViewSwitch,
  isHeroSearch,
}: PropertiesSearchFormProps) {
  const classes = useStyles();
  const screenType = useScreenType();

  const [visibleDropdown, setVisibleDropdown] = React.useState<Dropdown | null>(
    null
  );
  const [bedroomLabels, setBedroomLabels] = React.useState<string[]>([]);

  const selectDropdown = (selectedDropdown: Dropdown) => {
    if (visibleDropdown === selectedDropdown) {
      setVisibleDropdown(null);
    } else {
      setVisibleDropdown(selectedDropdown);
    }
  };

  const closeDropdown = () => setVisibleDropdown(null);

  const searchRadiusValues = getSearchRadiusValues();

  const singleValueOnChangeFactory =
    ({
      filterName,
      validate,
      underlyingValueType = 'string',
      isPrimitiveAttribute,
    }: {
      filterName: string;
      validate?: ValidationFn;
      underlyingValueType?: 'string' | 'boolean' | 'number';
      isPrimitiveAttribute?: boolean;
    }) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      const canSet = validate ? validate(value) : true;

      if (canSet) {
        let valueToBeSet;
        switch (underlyingValueType) {
          case 'number': {
            valueToBeSet = Number(value);
            break;
          }
          case 'boolean': {
            const currentValue = isPrimitiveAttribute
              ? propertiesPrimitiveAttributesFilters[filterName]
              : propertiesFilters[filterName];

            valueToBeSet = currentValue ? undefined : true;
            break;
          }
          default: {
            valueToBeSet = value;
          }
        }

        if (isPrimitiveAttribute) {
          setPropertiesPrimitiveAttributesFilters(filterName, valueToBeSet);
        } else {
          setPropertiesFilter(filterName, valueToBeSet);
        }
      }
    };

  const multiValueOnChangeFactory =
    ({
      filterName,
      validate,
      isUnderlyingValueNumber,
      isPrimitiveAttribute,
    }: {
      filterName: string;
      validate?: ValidationFn;
      isUnderlyingValueNumber?: boolean;
      isPrimitiveAttribute?: boolean;
    }) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.name;

      const canSet = validate ? validate(value) : true;

      if (canSet) {
        const currentSelectedValues: unknown[] = isPrimitiveAttribute
          ? propertiesPrimitiveAttributesFilters[filterName]
          : propertiesFilters[filterName];

        const currentSelectedValuesAsStr =
          currentSelectedValues?.map((v) => `${v}`) ?? [];

        const valueIndex = currentSelectedValuesAsStr.indexOf(value);

        if (valueIndex >= 0) {
          currentSelectedValuesAsStr.splice(valueIndex, 1);
        } else {
          currentSelectedValuesAsStr.push(value);
        }

        const newSelectedValues = isUnderlyingValueNumber
          ? currentSelectedValuesAsStr.map((v) => Number(v))
          : currentSelectedValuesAsStr;

        if (isPrimitiveAttribute) {
          setPropertiesPrimitiveAttributesFilters(
            filterName,
            newSelectedValues
          );
        } else {
          setPropertiesFilter(filterName, newSelectedValues);
        }
      }
    };

  const validatePrice = (value: number) =>
    value >= defaultRentRangeValues[0] && value <= defaultRentRangeValues[1];

  const moreFacilitiesAndAmenitiesGroupItems =
    getMoreFilterItemsPrimitiveAttributesBoolean({
      items: [
        { label: 'Concierge', key: 'attr_concierge' },
        { label: 'Parcel collection/storage', key: 'attr_parcel' },
        { label: 'Onsite maintenance', key: 'attr_onsite_maintenance' },
        { label: 'Bike storage', key: 'attr_bike_storage' },
        { label: 'Parking', key: 'attr_parking' },
        { label: 'Lift/Elevator', key: 'attr_lift' },
        { label: 'Dining room', key: 'attr_dining_room' },
        { label: 'Bar', key: 'attr_bar' },
        { label: 'Free wifi in communal area', key: 'attr_wifi' },
        { label: 'Terrace', key: 'attr_roof_terrace' },
        { label: 'Communal garden', key: 'attr_communal_garden' },
        { label: 'Gym & fitness center', key: 'attr_gym' },
        { label: 'Swimming pool', key: 'attr_swimming_pool' },
        { label: 'Common area', key: 'attr_common_area' },
        { label: 'Entertainment area', key: 'attr_entertainment_area' },
        { label: 'Cinema room', key: 'attr_cinema' },
        { label: 'Work zone', key: 'attr_work_area' },
        { label: 'Social events', key: 'attr_social_events' },
        { label: 'CCTV', key: 'attr_cctv' },
        { label: 'Controlled access', key: 'attr_controlled_access' },
        { label: 'Video door entry', key: 'attr_video_door_entry' },
        { label: 'Onsite management', key: 'attr_onsite_management' },
      ],
      primitiveAttributesFilters: propertiesPrimitiveAttributesFilters,
      onChangeFactory: singleValueOnChangeFactory,
    });

  const moreFurnishingOptionsGroupItems =
    getMoreFilterItemsPrimitiveAttributesBoolean({
      items: [
        { label: 'TV', key: 'fttr_tv' },
        { label: 'TV stand', key: 'fttr_tv_stand' },
        { label: 'Fridge & freezer', key: 'fttr_fridge' },
        { label: 'Oven', key: 'fttr_oven' },
        { label: 'Microwave', key: 'fttr_microwave' },
        { label: 'Dishwasher', key: 'fttr_dishwasher' },
        { label: 'Washer', key: 'fttr_washing_machine' },
        { label: 'Dryer', key: 'fttr_dryer' },
        { label: 'Smart meter', key: 'fttr_smart_meter' },
        { label: 'Smoke detector', key: 'attr_smoke_alarm' },
        { label: 'Bed & mattress', key: 'fttr_bed_and_mattress' },
        { label: 'Wardrobe', key: 'fttr_built_in_wardrobe' },
        { label: 'Bedside tables', key: 'fttr_drawers' },
        { label: 'Sofa', key: 'fttr_sofa' },
        { label: 'Coffee table', key: 'fttr_coffee_table' },
        { label: 'Dining table & chairs', key: 'fttr_dining_table_and_chairs' },
      ],
      primitiveAttributesFilters: propertiesPrimitiveAttributesFilters,
      onChangeFactory: singleValueOnChangeFactory,
    });

  const moreAtmosphereGroupItems = getMoreFilterItemsPrimitiveAttributesBoolean(
    {
      items: [
        { label: 'Short rent accepted', key: 'short_rent' },
        { label: 'Pet friendly', key: 'fttr_pet_friendly' },
        { label: 'Family friendly', key: 'fttr_family_friendly' },
        { label: 'Student friendly', key: 'fttr_student_friendly' },
      ],
      primitiveAttributesFilters: propertiesPrimitiveAttributesFilters,
      onChangeFactory: singleValueOnChangeFactory,
    }
  );

  const heroSearchRadiusValuesAndLabels = getHeroSearchRadiusValues();
  const heroSearchRadiusValues = heroSearchRadiusValuesAndLabels.map(
    (v) => v.value
  );
  const heroSearchRadiusLabels = heroSearchRadiusValuesAndLabels.map(
    (v) => v.label
  );

  const heroSearchBedroomValues = bedroomsValues.map((val) =>
    Number(val.value)
  );
  const heroSearchBedroomValueLabels = bedroomsValues.map((val) => val.label);

  const selectedBedrooms = propertiesFilters.flatType?.map((v) => `${v}`) ?? [];

  const handleMoreCardCancelButtonClick = () => {
    setVisibleDropdown(null);
  };

  const handleMoreCardResetButtonClick = () => {
    resetFilters();
  };

  return (
    <Box className={classes.propertiesSearchFormCtn}>
      <SearchBar {...searchBarProps} />

      <Box className={classes.visibleFiltersCtn}>
        {showSortRecommendedBtn && (
          <SortByRecommendedButton
            sortType={sortType}
            setSortType={setSortType}
          />
        )}

        {showSortPriceBtn && (
          <SortByPriceButton sortType={sortType} setSortType={setSortType} />
        )}

        {showSearchRadiusFilter && (
          <FilterButtonWithDropdown
            buttonText="Search radius"
            buttonOnClick={() => selectDropdown(Dropdown.SEARCH_RADIUS)}
            dropdownOpen={visibleDropdown === Dropdown.SEARCH_RADIUS}
            filtered={
              propertiesFilters.radius !== undefined &&
              searchRadiusValues.some(
                ({ value }) => value === `${propertiesFilters.radius}`
              )
            }
          >
            <DropdownPopper closePopper={closeDropdown}>
              <RadiosFilter
                values={searchRadiusValues}
                selectedValue={`${propertiesFilters.radius}`}
                onChange={singleValueOnChangeFactory({
                  filterName: 'radius',
                  underlyingValueType: 'number',
                })}
              />
            </DropdownPopper>
          </FilterButtonWithDropdown>
        )}

        {showBedroomsFilter && (
          <FilterButtonWithDropdown
            buttonText="Bedrooms"
            buttonOnClick={() => selectDropdown(Dropdown.BEDROOMS)}
            dropdownOpen={visibleDropdown === Dropdown.BEDROOMS}
            filtered={selectedBedrooms.length > 0}
          >
            <DropdownPopper closePopper={closeDropdown}>
              <MultiStringFilter
                values={bedroomsValues}
                selectedValues={selectedBedrooms}
                onChange={multiValueOnChangeFactory({
                  filterName: 'flatType',
                  isUnderlyingValueNumber: true,
                })}
              />
            </DropdownPopper>
          </FilterButtonWithDropdown>
        )}

        {showPriceFilter && (
          <FilterButtonWithDropdown
            buttonText="£ Price"
            buttonOnClick={() => selectDropdown(Dropdown.PRICE)}
            dropdownOpen={visibleDropdown === Dropdown.PRICE}
            filtered={
              (propertiesFilters.rentMin !== undefined &&
                propertiesFilters.rentMin > defaultRentRangeValues[0]) ||
              (propertiesFilters.rentMax !== undefined &&
                propertiesFilters.rentMax < defaultRentRangeValues[1])
            }
          >
            <DropdownPopper closePopper={closeDropdown}>
              <SliderFilter
                value={[
                  propertiesFilters.rentMin ?? defaultRentRangeValues[0],
                  propertiesFilters.rentMax ?? defaultRentRangeValues[1],
                ]}
                setRentMin={(value: number) =>
                  setPropertiesFilter('rentMin', value)
                }
                setRentMax={(value: number) =>
                  setPropertiesFilter('rentMax', value)
                }
                validate={validatePrice}
                min={defaultRentRangeValues[0]}
                max={defaultRentRangeValues[1]}
                step={defaultRentRangeStep}
              />
            </DropdownPopper>
          </FilterButtonWithDropdown>
        )}

        {showFurnishingFilter && (
          <FilterButtonWithDropdown
            buttonText="Furnishing"
            buttonOnClick={() => selectDropdown(Dropdown.FURNISHING)}
            dropdownOpen={visibleDropdown === Dropdown.FURNISHING}
          >
            <DropdownPopper closePopper={closeDropdown}>
              <MultiStringFilter
                values={furnishingValues}
                selectedValues={
                  propertiesPrimitiveAttributesFilters.attr_furnished ?? []
                }
                onChange={multiValueOnChangeFactory({
                  filterName: 'attr_furnished',
                  isPrimitiveAttribute: true,
                })}
              />
            </DropdownPopper>
          </FilterButtonWithDropdown>
        )}

        {showPetFriendlyFilter && (
          <FilterButtonWithDropdown
            filtered={propertiesPrimitiveAttributesFilters.fttr_pet_friendly}
            buttonText="Pet friendly"
            buttonOnClick={() => selectDropdown(Dropdown.PET_FRIENDLY)}
            dropdownOpen={visibleDropdown === Dropdown.PET_FRIENDLY}
          >
            <DropdownPopper closePopper={closeDropdown}>
              <BooleanFilter
                value={propertiesPrimitiveAttributesFilters.fttr_pet_friendly}
                label="Pet friendly"
                onChange={singleValueOnChangeFactory({
                  filterName: 'fttr_pet_friendly',
                  underlyingValueType: 'boolean',
                  isPrimitiveAttribute: true,
                })}
              />
            </DropdownPopper>
          </FilterButtonWithDropdown>
        )}

        {showZeroDepositFilter && (
          <FilterButtonWithDropdown
            buttonText="Zero deposit"
            buttonOnClick={() => selectDropdown(Dropdown.ZERO_DEPOSIT)}
            dropdownOpen={visibleDropdown === Dropdown.ZERO_DEPOSIT}
          >
            <DropdownPopper closePopper={closeDropdown}>
              <BooleanFilter
                value={propertiesPrimitiveAttributesFilters.zero_deposit}
                label="Zero deposit"
                onChange={singleValueOnChangeFactory({
                  filterName: 'zero_deposit',
                  underlyingValueType: 'boolean',
                  isPrimitiveAttribute: true,
                })}
              />
            </DropdownPopper>
          </FilterButtonWithDropdown>
        )}

        {showMoreFilter && (
          <FilterButtonWithDropdown
            buttonText="More +"
            buttonOnClick={() => selectDropdown(Dropdown.MORE)}
            dropdownOpen={visibleDropdown === Dropdown.MORE}
            filtered={moreDropdownFiltered({
              propertiesFilters,
              propertiesPrimitiveAttributesFilters,
              screenType,
            })}
          >
            <DropdownPopper closePopper={closeDropdown}>
              <MoreCardContainer
                onCancel={handleMoreCardCancelButtonClick}
                onReset={handleMoreCardResetButtonClick}
              >
                {!showSearchRadiusFilter && (
                  <FilterGroup title="Radius">
                    <RadiosFilter
                      values={searchRadiusValues}
                      selectedValue={`${propertiesFilters.radius}`}
                      onChange={singleValueOnChangeFactory({
                        filterName: 'radius',
                        underlyingValueType: 'number',
                      })}
                    />
                  </FilterGroup>
                )}

                {!showBedroomsFilter && (
                  <FilterGroup title="Bedrooms">
                    <MultiStringFilter
                      values={bedroomsValues}
                      selectedValues={
                        propertiesFilters.flatType?.map((v) => `${v}`) ?? []
                      }
                      onChange={multiValueOnChangeFactory({
                        filterName: 'flatType',
                        isUnderlyingValueNumber: true,
                      })}
                    />
                  </FilterGroup>
                )}

                {!showPriceFilter && (
                  <FilterGroup title="£ Price">
                    <SliderFilter
                      value={[
                        propertiesFilters.rentMin ?? defaultRentRangeValues[0],
                        propertiesFilters.rentMax ?? defaultRentRangeValues[1],
                      ]}
                      setRentMin={(value: number) =>
                        setPropertiesFilter('rentMin', value)
                      }
                      setRentMax={(value: number) =>
                        setPropertiesFilter('rentMax', value)
                      }
                      validate={validatePrice}
                      min={defaultRentRangeValues[0]}
                      max={defaultRentRangeValues[1]}
                      step={defaultRentRangeStep}
                    />
                  </FilterGroup>
                )}

                <FilterGroup title="Bills included">
                  <MultiStringFilter
                    values={billsIncludedValues}
                    selectedValues={
                      propertiesFilters.billsIncluded
                        ? propertiesFilters.billsIncluded.map((it) => `${it}`)
                        : []
                    }
                    onChange={multiValueOnChangeFactory({
                      filterName: 'billsIncluded',
                      isPrimitiveAttribute: false,
                    })}
                  />
                </FilterGroup>

                <FilterGroup title="Hob type">
                  <MultiStringFilter
                    values={hobTypeValues}
                    selectedValues={
                      propertiesPrimitiveAttributesFilters.fttr_hob_type ?? []
                    }
                    onChange={multiValueOnChangeFactory({
                      filterName: 'fttr_hob_type',
                      isPrimitiveAttribute: true,
                    })}
                  />
                </FilterGroup>

                <FilterGroup title="Bed size">
                  <MultiStringFilter
                    values={bedSizeValues}
                    selectedValues={
                      propertiesPrimitiveAttributesFilters.fttr_bed_size ?? []
                    }
                    onChange={multiValueOnChangeFactory({
                      filterName: 'fttr_bed_size',
                      isPrimitiveAttribute: true,
                    })}
                  />
                </FilterGroup>

                {!showFurnishingFilter && (
                  <FilterGroup title="Furnishing">
                    <MultiStringFilter
                      values={furnishingValues}
                      selectedValues={
                        propertiesPrimitiveAttributesFilters.attr_furnished ??
                        []
                      }
                      onChange={multiValueOnChangeFactory({
                        filterName: 'attr_furnished',
                        isPrimitiveAttribute: true,
                      })}
                    />
                  </FilterGroup>
                )}

                {!showZeroDepositFilter && (
                  <FilterGroup title="Deposit">
                    <BooleanFilter
                      value={propertiesPrimitiveAttributesFilters.zero_deposit}
                      label="Zero deposit"
                      onChange={singleValueOnChangeFactory({
                        filterName: 'zero_deposit',
                        underlyingValueType: 'boolean',
                        isPrimitiveAttribute: true,
                      })}
                    />
                    <MultiStringFilter
                      values={depositTermValues}
                      selectedValues={
                        propertiesPrimitiveAttributesFilters.deposit_term ?? []
                      }
                      onChange={multiValueOnChangeFactory({
                        filterName: 'deposit_term',
                        isPrimitiveAttribute: true,
                      })}
                    />
                  </FilterGroup>
                )}

                <FilterGroup title="Break clause">
                  <MultiStringFilter
                    values={breakClauseValues}
                    selectedValues={
                      propertiesPrimitiveAttributesFilters.break_clause ?? []
                    }
                    onChange={multiValueOnChangeFactory({
                      filterName: 'break_clause',
                      isPrimitiveAttribute: true,
                    })}
                  />
                </FilterGroup>

                <FilterGroup title="Notice period">
                  <MultiStringFilter
                    values={noticePeriodValues}
                    selectedValues={
                      propertiesPrimitiveAttributesFilters.notice_period ?? []
                    }
                    onChange={multiValueOnChangeFactory({
                      filterName: 'notice_period',
                      isPrimitiveAttribute: true,
                    })}
                  />
                </FilterGroup>

                <BooleanFilterGroup
                  title="Facilities & amenities"
                  items={moreFacilitiesAndAmenitiesGroupItems}
                />
                <BooleanFilterGroup
                  title="Furnishing options"
                  items={moreFurnishingOptionsGroupItems}
                />
                <BooleanFilterGroup
                  title="Atmosphere"
                  items={moreAtmosphereGroupItems}
                />
              </MoreCardContainer>
            </DropdownPopper>
          </FilterButtonWithDropdown>
        )}

        {showViewSwitch && viewMode !== undefined && setViewMode && (
          <ViewSwitchButton viewMode={viewMode} setViewMode={setViewMode} />
        )}
      </Box>

      {isHeroSearch && (
        <Box className={classes.heroSearchFiltersCtn}>
          <SelectFilter
            name="radius"
            label="Within Radius"
            value={`${propertiesFilters.radius}`}
            values={heroSearchRadiusValues}
            valuesLabels={heroSearchRadiusLabels}
            className={classes.customColorSelect}
            native={screenType === ScreenType.MOBILE}
            handleChange={singleValueOnChangeFactory({
              filterName: 'radius',
              underlyingValueType: 'number',
            })}
          />
          <SelectFilter
            name="bedroomType"
            label="Bedrooms"
            className={classes.customColorSelect}
            multiple
            value={propertiesFilters.flatType ?? []}
            values={heroSearchBedroomValues}
            valuesLabels={heroSearchBedroomValueLabels}
            native={screenType === ScreenType.MOBILE}
            selectedValuesLabels={bedroomLabels}
            handleChange={(
              e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
            ) => {
              const { options } = e.target as HTMLSelectElement;

              if (screenType === ScreenType.MOBILE && options !== null) {
                // Native multi-select
                // Note that 'options' is NOT an array (but rather a
                // HTMLOptionsCollection). Normal array methods won't work here.

                const value: number[] = [];
                const labels: string[] = [];
                for (let i = 0, l = options.length; i < l; i += 1) {
                  if (options[i].selected) {
                    value.push(Number(options[i].value));
                    labels.push(options[i].title);
                  }
                }
                setPropertiesFilter('flatType', value);
                setBedroomLabels(labels);
              } else {
                setPropertiesFilter('flatType', e.target.value);
              }
            }}
          />
          <NumberFilter
            name="maxRent"
            label="Max rent (pcm)"
            className={classes.customColorSelect}
            inputProps={{
              min: defaultRentRangeValues[0],
              max: defaultRentRangeValues[1],
            }}
            value={propertiesFilters.rentMax}
            handleChange={(e) =>
              setPropertiesFilter(
                'rentMax',
                Number(e.target.value) || undefined
              )
            }
          />
          <Box className={classes.searchBtnCtn}>
            <Button
              variant="contained"
              className={classes.btnSearch}
              onClick={searchBarProps.search}
            >
              Search
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
