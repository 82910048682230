import * as React from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { BrokenImage } from '@material-ui/icons';
import { Link } from '../../../atoms';
import { getRecentlyViewedProperties } from '../../../../api/utils/localStorage';
import CloudinaryPicture from '../../../atoms/CloudinaryPicture/CloudinaryPicture';
import { formatMoney } from '../../../../utils/number';
import useStyles from './styles';

function RecentlyViewed() {
  const classes = useStyles();
  const recentlyViewedProperties = getRecentlyViewedProperties();

  return (
    <Box
      width="100%"
      className={classes.recentlyViewed}
      boxShadow="0 0 40px rgba(0,0,0,0.11)"
    >
      <Typography className={classes.recentlyViewedTitle} variant="h6">
        Recently viewed
      </Typography>
      <List className={classes.recentlyViewedList}>
        {!recentlyViewedProperties && <span>Not yet viewed any property</span>}
        {recentlyViewedProperties.map((recentlyViewedProperty) => (
          <Link
            key={recentlyViewedProperty.developmentId}
            to={`/properties/${recentlyViewedProperty.developmentId}`}
            noColor
          >
            <ListItem className={classes.recentlyViewedListItem}>
              {recentlyViewedProperty.src ? (
                <CloudinaryPicture
                  imgSrc={recentlyViewedProperty.src}
                  imgAlt={`${recentlyViewedProperty.title} Image`}
                  imgProps={{
                    className: classes.recentlyViewedListItemImg,
                    height: 60,
                  }}
                  config={{
                    '0': {
                      height: 60,
                    },
                  }}
                />
              ) : (
                <Box className={classes.emptyImg}>
                  <BrokenImage />
                </Box>
              )}

              <Box className={classes.recentlyViewedListItemTextCtn}>
                <Typography className={classes.recentlyViewedListItemTitle}>
                  {recentlyViewedProperty.title}
                </Typography>
                <Typography>
                  From{' '}
                  <span className={classes.recentlyViewedListItemPrice}>
                    {formatMoney(recentlyViewedProperty.price, 'GBP')}
                  </span>
                </Typography>
              </Box>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );
}

export default RecentlyViewed;
