import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import API_ENDPOINTS from '../../endpoints';
import pubFetcher from '../../utils/fetcher';

export interface FetchPromotedPropertiesProps {
  city: string;
}

export interface PromotedProperties {
  developmentId: number;
  title: string;
  address: string | null;
  postcode: string | null;
  city: string | null;
  promotionPriority: number | null;
  imgThumbnailId: string | null;

  // This 'rentPcm' is the cheapest rent that is > 0
  flats: { rentPcm: number }[];

  // This contains a maximum of 2 nearby stations
  developmentNearbyPlaces: {
    placeType: 'train_station' | 'light_rail_station' | 'subway_station';
    placeDistanceM: number;
    placeDatum: { name: string };
  }[];

  developmentAnalytic: {
    developmentViewCount30day: number;
  };
}

export interface FetchPromotedPropertiesReturn {
  data: PromotedProperties[];
  meta: {
    count: number;
  };
}

export function fetchPromotedProperties({
  city,
}: FetchPromotedPropertiesProps): Promise<FetchPromotedPropertiesReturn> {
  const url = `${API_ENDPOINTS.PROMOTED_PROPERTIES}?city=${city}`;

  return pubFetcher<FetchPromotedPropertiesReturn>(url);
}

interface UseFetchPromotedPropertiesProps extends FetchPromotedPropertiesProps {
  queryConfig?: UseQueryOptions<FetchPromotedPropertiesReturn, Error>;
}

export default function usePromotedProperties({
  city,
  queryConfig,
}: UseFetchPromotedPropertiesProps) {
  return useQuery(
    [API_ENDPOINTS, city],
    () => fetchPromotedProperties({ city }),
    queryConfig
  );
}
