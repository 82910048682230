import { FlatDetailsCategory } from '../../api/property/models';
import { PropertyV2 } from '../../api/property/types';

export const defaultFlatTitles: Record<FlatDetailsCategory, string> = {
  [FlatDetailsCategory.PRIVATE_STUDIO]: 'Studio',
  [FlatDetailsCategory.PRIVATE_ONE_BEDROOM]: '1-Bedroom',
  [FlatDetailsCategory.PRIVATE_TWO_BEDROOMS]: '2-Bedroom',
  [FlatDetailsCategory.PRIVATE_THREE_BEDROOMS]: '3-Bedroom',
  [FlatDetailsCategory.PRIVATE_FOUR_BEDROOMS]: '4-Bedroom',
  [FlatDetailsCategory.SHARED]: 'Private Room',
  [FlatDetailsCategory.SHARED_ENSUITE]: 'Private Ensuite Room',
};

// This is used to properly order the flat prices
export const flatCategoryPriority: Record<FlatDetailsCategory, number> = {
  [FlatDetailsCategory.PRIVATE_STUDIO]: 0,
  [FlatDetailsCategory.PRIVATE_ONE_BEDROOM]: 1,
  [FlatDetailsCategory.PRIVATE_TWO_BEDROOMS]: 2,
  [FlatDetailsCategory.PRIVATE_THREE_BEDROOMS]: 3,
  [FlatDetailsCategory.PRIVATE_FOUR_BEDROOMS]: 4,
  [FlatDetailsCategory.SHARED]: 5,
  [FlatDetailsCategory.SHARED_ENSUITE]: 6,
};

export default function getFlatPrices(property: PropertyV2): Array<{
  title: string;
  price: number;
}> {
  const flatsDetails = property.flats_details;

  if (flatsDetails) {
    return [...flatsDetails]
      .sort(
        (a, b) =>
          flatCategoryPriority[a.flat_category] -
          flatCategoryPriority[b.flat_category]
      )
      .map(({ rent_pcm, flat_category }) => ({
        price: rent_pcm,
        title: defaultFlatTitles[flat_category],
      }));
  }

  return [];
}
