import * as React from 'react';
import { Box, debounce, Input, Slider, SliderProps } from '@material-ui/core';
import { Typography } from '../../../../atoms';
import useStyles from './SliderFilter.styles';

export interface SliderFilterProps extends SliderProps {
  value: number[];

  setRentMin: (value: number) => void;
  setRentMax: (value: number) => void;
  validate: (value: number) => boolean;
}

const DEBOUNCE_TIME = 500; // ms

export default function SliderFilter({
  value,
  setRentMin,
  setRentMax,
  validate,
  ...sliderProps
}: SliderFilterProps) {
  const classes = useStyles();

  const [localSliderMin, setLocalSliderMin] = React.useState(value[0]);
  const [localSliderMax, setLocalSliderMax] = React.useState(value[1]);

  const updateFilter = React.useCallback(
    debounce(({ min, max }: { min?: number; max?: number }) => {
      if (min) {
        setRentMin(min);
      }
      if (max) {
        setRentMax(max);
      }
    }, DEBOUNCE_TIME),
    [DEBOUNCE_TIME]
  );

  const onSliderChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: number[]
  ) => {
    const canSet = validate(value[0]) && validate(value[1]);

    if (canSet) {
      setLocalSliderMin(Number(value[0]));
      setLocalSliderMax(Number(value[1]));
      updateFilter({ min: Number(value[0]), max: Number(value[1]) });
    }
  };

  const onInputMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    const canSet = validate(value);

    if (canSet) {
      setLocalSliderMin(Number(value));
      updateFilter({ min: Number(value) });
    }
  };

  const onInputMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    const canSet = validate(value);

    if (canSet) {
      setLocalSliderMax(Number(value));
      updateFilter({ max: Number(value) });
    }
  };

  return (
    <Box className={classes.sliderCtn}>
      <Slider
        value={[localSliderMin, localSliderMax]}
        onChange={onSliderChange}
        valueLabelDisplay="off"
        {...sliderProps}
      />

      <Box className={classes.inputsCtn}>
        <Input
          inputProps={{
            type: 'number',
            min: sliderProps.min,
            max: sliderProps.max,
            step: sliderProps.step,
          }}
          value={localSliderMin}
          onChange={onInputMinChange}
        />
        <Typography
          className={classes.connector}
          variant="h5"
          fontWeight="bold"
        >
          -
        </Typography>
        <Input
          inputProps={{
            type: 'number',
            min: sliderProps.min,
            max: sliderProps.max,
            step: sliderProps.step,
          }}
          value={localSliderMax}
          onChange={onInputMaxChange}
        />
      </Box>
    </Box>
  );
}
