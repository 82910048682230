import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { DevelopmentImage, FlatImage } from './types';
import { propertyUrls } from './config';

export interface FetchPropertyImagesSuccessResponse {
  data: {
    developmentImages: DevelopmentImage[];
    flatImages: FlatImage[][];
  };
  meta: {
    flats: Array<{ flat_id: number; flat_title: string }>;
  };
}

interface UseFetchPropertyImagesProps {
  developmentId: number;
  queryConfig?: UseQueryOptions<any>;
}

export async function fetchPropertyImages(
  url: string
): Promise<FetchPropertyImagesSuccessResponse> {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(
      `Could not fetch property images. Reason: ${res.status} - ${res.statusText} - ${errMsg}`
    );
  }

  return res.json();
}

export default function useFetchPropertyImages({
  developmentId,
  queryConfig,
}: UseFetchPropertyImagesProps) {
  const url = propertyUrls.GET_PROPERTY_IMAGES(developmentId);

  return useQuery<FetchPropertyImagesSuccessResponse, Error>(
    [url],
    () => fetchPropertyImages(url),
    queryConfig
  );
}
