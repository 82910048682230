import * as React from 'react';
import { useAddressAutocomplete } from '../../../hooks';
import { usePrev } from '../../../utils/hook';
import {
  AutocompletePrediction,
  AutocompleteService,
  PlacesService,
} from '../../../api/googleMaps/gcpPlaces/types';
import { navigate } from '../../../utils/dom';
import { defaultRentRangeValues } from '../../organisms/PropertiesSearchForm/config';
import PropertiesSearchForm, {
  PropertiesSearchFormProps,
} from '../../organisms/PropertiesSearchForm/PropertiesSearchForm';
import {
  DevelopmentBaseFilter,
  DevelopmentFeesAttributesFilter,
  DevelopmentPrimitiveAttributesFilter,
} from '../../../api/property/useProperties/types';
import getFetchPropertiesPropsFromUrl, {
  defaultHeroSearchRadius,
  defaultPaginationItemsPerPage,
  defaultPaginationPage,
  defaultSearchRadius,
  defaultSortType,
} from '../../../utils/property/getFetchPropertiesPropsFromUrl';
import { PropertiesSearchSortType } from '../../../api/property/types';
import { PaginationProperties } from '../../../api/utils/types';

export interface ConnectedPropertiesSearchFormProps
  extends Omit<
    PropertiesSearchFormProps,
    | 'searchBarProps'
    | 'propertiesFilters'
    | 'propertiesPrimitiveAttributesFilters'
    | 'sortType'
    | 'paginationProperties'
    | 'setPropertiesFilter'
    | 'setPropertiesPrimitiveAttributesFilters'
    | 'setSortType'
    | 'setPaginationProperty'
    | 'resetFilters'
  > {
  // These are necessary to use Google's Autocomplete and Places services
  autocompleteService: AutocompleteService | null | undefined;
  placesService: PlacesService | null | undefined;

  // This is the current URL's 'search' portion (i.e. query parameters)
  search: string;

  // Redirect to a "default" search if the search address is empty
  redirectIfSearchAddressIsEmpty?: boolean;
  // The pathname of the redirection. This is needed because we could be
  // redirecting to either the "/properties" or the "/map" page, depending on
  // where the user is.
  redirectPathname?: string;

  // If true, will show the "Search" button
  hasSearchButton?: boolean;

  // If true, will not automatically add pagination properties to the URL
  noAutoPagination?: boolean;
}

export default function ConnectedPropertiesSearchForm({
  autocompleteService,
  placesService,
  search,
  hasSearchButton,
  redirectIfSearchAddressIsEmpty,
  redirectPathname = '/',
  noAutoPagination,
  isHeroSearch,
  ...rest
}: ConnectedPropertiesSearchFormProps) {
  const {
    address: initialAddress,
    filters: {
      radius: initialRadius,
      flatType: initialFlatType,
      rentMax: initialRentMax,
      rentMin: initialRentMin,
      billsIncluded: initialBillsIncluded,
      primitiveAttributes: initialPrimitiveAttributes,
    },
    sort: initialSortType,
    pagination,
  } = getFetchPropertiesPropsFromUrl({
    search,
  });

  let initialPage: number | undefined;
  let initialItemsPerPage: PaginationProperties['itemsPerPage'] | undefined;
  if (pagination) {
    initialPage = pagination.page;
    initialItemsPerPage = pagination.itemsPerPage;
  }

  const initialDevelopmentBaseFilter: DevelopmentBaseFilter = {
    radius: isHeroSearch
      ? defaultHeroSearchRadius
      : initialRadius ?? defaultSearchRadius,
    flatType: initialFlatType ?? [],
    rentMin: initialRentMin ?? defaultRentRangeValues[0],
    rentMax: isHeroSearch
      ? undefined
      : initialRentMax ?? defaultRentRangeValues[1],
    billsIncluded: initialBillsIncluded ?? [],
  };
  const initialDevelopmentPrimitiveAttributesFilter: DevelopmentFeesAttributesFilter =
    initialPrimitiveAttributes ?? {};

  const {
    inputValue,
    setInputValue,
    predictions,
    setPredictions,
    placeDetails,
    getPlaceDetails,
    selectedAutocompletePrediction,
    setSelectedAutocompletePrediction,
  } = useAddressAutocomplete({
    autocompleteService,
    placesService,
    initialInputValue: initialAddress,
  });

  const [developmentBaseFilter, setDevelopmentBaseFilter] =
    React.useState<DevelopmentBaseFilter>(initialDevelopmentBaseFilter);
  const [
    developmentPrimitiveAttributesFilter,
    setDevelopmentPrimitiveAttributesFilter,
  ] = React.useState(initialDevelopmentPrimitiveAttributesFilter);
  const [sortType, setSortType] = React.useState<PropertiesSearchSortType>(
    initialSortType ?? defaultSortType
  );
  const [paginationProperties, setPaginationProperties] =
    React.useState<PaginationProperties>({
      page: initialPage ?? defaultPaginationPage,
      itemsPerPage: initialItemsPerPage ?? defaultPaginationItemsPerPage,
    });

  const currentPlaceId = placeDetails?.place_id;
  const prevPlaceId = usePrev(currentPlaceId);

  const selectAutocompletePrediction = (
    prediction: AutocompletePrediction | null
  ) => {
    setPredictions(
      prediction && predictions ? [prediction, ...predictions] : predictions
    );
    setSelectedAutocompletePrediction(prediction);
    if (prediction) {
      getPlaceDetails(prediction.place_id);
    }
  };

  // From filter object to URLSearchParams
  const searchSomething = (address: string) => {
    const parsedFiltersPrimitiveAttributes = Object.entries(
      developmentPrimitiveAttributesFilter
    ).reduce<
      Partial<Record<keyof DevelopmentPrimitiveAttributesFilter, string>>
    >((acc, [k, v]) => {
      if (v !== undefined) {
        if (Array.isArray(v)) {
          if (v.length > 0) {
            acc[k] = v.join(',');
          }
        } else {
          acc[k] = `${v}`;
        }
      }

      return acc;
    }, {});

    const parsedBaseFilter: Partial<
      Record<keyof DevelopmentBaseFilter, string>
    > = {};

    const { radius, rentMin, rentMax, flatType, billsIncluded } =
      developmentBaseFilter;

    if (radius) {
      parsedBaseFilter.radius = `${radius}`;
    }
    if (rentMin) {
      parsedBaseFilter.rentMin = `${rentMin}`;
    }
    if (rentMax) {
      parsedBaseFilter.rentMax = `${rentMax}`;
    }
    if (flatType && flatType.length > 0) {
      parsedBaseFilter.flatType = flatType.join(',');
    }
    if (billsIncluded && billsIncluded.length > 0) {
      parsedBaseFilter.billsIncluded = billsIncluded.join(',');
    }

    const { page, itemsPerPage } = paginationProperties;

    let newSearch: URLSearchParams;

    if (noAutoPagination) {
      newSearch = new URLSearchParams({
        address,
        ...parsedBaseFilter,
        ...parsedFiltersPrimitiveAttributes,
        sort: sortType ?? defaultSortType,
      });
    } else {
      newSearch = new URLSearchParams({
        address,
        ...parsedBaseFilter,
        ...parsedFiltersPrimitiveAttributes,
        sort: sortType ?? defaultSortType,
        page: page ? `${page}` : `${defaultPaginationPage}`,
        itemsPerPage: itemsPerPage
          ? `${itemsPerPage}`
          : `${defaultPaginationItemsPerPage}`,
      });
    }

    return navigate(`${redirectPathname}/?${newSearch.toString()}`);
  };

  const searchFn = () => {
    const addressToSearch = inputValue || initialAddress;

    if (addressToSearch) {
      void searchSomething(addressToSearch);
    }
  };

  // This allows the web app to trigger a search when the user clicks on an
  // Autocomplete suggestion
  React.useEffect(() => {
    if (
      initialAddress &&
      currentPlaceId &&
      currentPlaceId !== prevPlaceId &&
      placeDetails
    ) {
      void searchSomething(placeDetails.formatted_address ?? '');
    }
  }, [currentPlaceId, searchSomething]);

  // This allows the web app to trigger a search when the user changes the sortType
  // type or filters
  React.useEffect(() => {
    if (initialAddress) {
      setInputValue(initialAddress);
      void searchSomething(initialAddress);
    }
  }, [sortType, developmentBaseFilter, developmentPrimitiveAttributesFilter]);

  // Redirect to the "default" property search page if search query is empty
  React.useEffect(() => {
    if (!initialAddress && redirectIfSearchAddressIsEmpty) {
      void searchSomething('London, UK');
      setInputValue('London, UK');
    }
  }, [initialAddress, redirectIfSearchAddressIsEmpty, searchSomething]);

  const setPropertiesFilters = (name: string, value: unknown) => {
    setDevelopmentBaseFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const setPropertiesPrimitiveAttributesFilters = (
    name: string,
    value: unknown
  ) => {
    setDevelopmentPrimitiveAttributesFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const setPaginationProperty = (
    paginationProperty: 'page' | 'itemsPerPage',
    value: number
  ) => {
    setPaginationProperties((prev) => ({
      ...prev,
      [paginationProperty]: value,
    }));
  };

  const resetFilters = () => {
    setDevelopmentBaseFilter({
      radius: defaultSearchRadius,
      flatType: [],
      rentMin: defaultRentRangeValues[0],
      rentMax: defaultRentRangeValues[1],
      billsIncluded: [],
    });
    setDevelopmentPrimitiveAttributesFilter({});
  };

  return (
    <PropertiesSearchForm
      searchBarProps={{
        hasSearchButton,
        search: searchFn,
        addressAutocompleteProps: {
          inputValue,
          setInputValue,
          autocompletePredictions: predictions,
          selectedAutocompletePrediction,
          selectAutocompletePrediction,
        },
      }}
      propertiesFilters={developmentBaseFilter}
      setPropertiesFilter={setPropertiesFilters}
      sortType={sortType}
      paginationProperties={paginationProperties}
      propertiesPrimitiveAttributesFilters={
        developmentPrimitiveAttributesFilter
      }
      setPropertiesPrimitiveAttributesFilters={
        setPropertiesPrimitiveAttributesFilters
      }
      setSortType={setSortType}
      setPaginationProperty={setPaginationProperty}
      resetFilters={resetFilters}
      isHeroSearch={isHeroSearch}
      {...rest}
    />
  );
}
