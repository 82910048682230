import * as React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Button, Typography } from '../../../../atoms';
import { PropertiesSearchSortType } from '../../../../../api/property/types';
import { SetSortType } from '../../types';
import useStyles from './SortByPriceButton.styles';

export interface SortByPriceButtonProps {
  sortType: PropertiesSearchSortType;
  setSortType: SetSortType;
}

export default function SortByPriceButton({
  sortType,
  setSortType,
}: SortByPriceButtonProps) {
  const classes = useStyles();

  const isSortHighToLow =
    sortType === PropertiesSearchSortType.PRICE_HIGH_TO_LOW;
  const isSortLowToHigh =
    sortType === PropertiesSearchSortType.PRICE_LOW_TO_HIGH;

  const handleClick = () => {
    if (isSortHighToLow) {
      setSortType(PropertiesSearchSortType.PRICE_LOW_TO_HIGH);
    } else {
      // Includes the case when sortType type is currently "RECOMMENDED"
      setSortType(PropertiesSearchSortType.PRICE_HIGH_TO_LOW);
    }
  };

  return (
    <Button variant="outlined" onClick={handleClick}>
      <Typography
        component="span"
        variant="body2"
        color={isSortHighToLow || isSortLowToHigh ? 'primary' : undefined}
        fontWeight={isSortHighToLow || isSortLowToHigh ? 'bold' : undefined}
      >
        Price
      </Typography>
      <Box className={classes.arrowsCtn}>
        <ArrowDropUp
          className={clsx(classes.arrowUp, isSortHighToLow && classes.active)}
        />
        <ArrowDropDown
          className={clsx(classes.arrowDown, isSortLowToHigh && classes.active)}
        />
      </Box>
    </Button>
  );
}
