import * as React from 'react';
import { Box, Button } from '@material-ui/core';
import useStyles from './styles';
import { navigate } from '../../../../utils/dom';
import { APP_ROUTES } from '../../../../config/app';

interface ViewMapProps {
  search?: string;
}

function ViewMap({ search }: ViewMapProps) {
  const classes = useStyles();

  const handleClick = () => {
    void navigate(`${APP_ROUTES.map}/${search}`);
  };

  return (
    <Box width="100%" position="relative" boxShadow="0 0 40px rgba(0,0,0,0.11)">
      <img src="/images/cities/map.png" alt="Generic map" width="100%" />
      <Box className={classes.viewMap}>
        <Button onClick={handleClick} variant="contained">
          View Map
        </Button>
      </Box>
    </Box>
  );
}

export default ViewMap;
