import { FetchPropertiesProps } from '../../api/property/useProperties/fetchProperties';
import { PaginationProperties } from '../../api/utils/types';
import { PropertiesSearchSortType } from '../../api/property/types';
import isPrimitiveAttribute from '../../api/property/useProperties/isPrimitiveAttribute';

export interface GetPropertiesSearchPropsFromUrlProps {
  search: string; // The current 'search' part of the URL
}

export const defaultSearchRadius = 5000; // 500 meters
export const defaultHeroSearchRadius = 15000; // 15 km

export const defaultSortType = PropertiesSearchSortType.RECOMMENDED;

export const defaultPaginationPage = 1;
export const defaultPaginationItemsPerPage = 10;

export const defaultFetchPropertiesProps: Required<FetchPropertiesProps> = {
  address: '',
  filters: {
    radius: defaultSearchRadius,
    primitiveAttributes: {},
  },
  sort: defaultSortType,
  excludeDevelopmentIds: [],
  pagination: {
    page: 1,
    itemsPerPage: 20,
  },
};

export default function getFetchPropertiesPropsFromUrl({
  search,
}: GetPropertiesSearchPropsFromUrlProps): FetchPropertiesProps {
  if (!search) return { ...defaultFetchPropertiesProps };

  // Create a deep copy of defaultFetchPropertiesProps
  const fetchPropertiesProps: FetchPropertiesProps = JSON.parse(
    JSON.stringify(defaultFetchPropertiesProps)
  );

  const urlSearchParams = new URLSearchParams(search);

  urlSearchParams.forEach((v, k) => {
    if (v === 'undefined') {
      return;
    }

    // ----- Address ----- //

    if (k === 'address') {
      fetchPropertiesProps.address = v;
    }

    // ----- Filters ----- //

    if (['radius', 'rentMin', 'rentMax'].includes(k)) {
      fetchPropertiesProps.filters[k] = Number(v);
    }

    if (k === 'flatType') {
      const parts = v.split(',');
      if (parts.length > 0) {
        fetchPropertiesProps.filters[k] = parts.map((it) => Number(it));
      }
    }

    if (k === 'billsIncluded') {
      const parts = v.split(',');
      if (parts.length > 0) {
        fetchPropertiesProps.filters[k] = parts.map((it) => Number(it));
      }
    }

    const isPrimitiveAttributeResult = isPrimitiveAttribute(k);
    if (isPrimitiveAttributeResult) {
      switch (isPrimitiveAttributeResult) {
        case 'string': {
          fetchPropertiesProps.filters.primitiveAttributes![k] = v;
          break;
        }
        case 'boolean': {
          fetchPropertiesProps.filters.primitiveAttributes![k] = v === 'true';
          break;
        }
        case 'stringarray': {
          const parts = v.split(',');
          if (parts.length > 0) {
            fetchPropertiesProps.filters.primitiveAttributes![k] = parts;
          }
          break;
        }
      }
    }

    // ----- Sort ----- //

    if (k === 'sort') {
      fetchPropertiesProps.sort = v as PropertiesSearchSortType;
    }

    // ----- Pagination properties ----- //

    if (k === 'page' && fetchPropertiesProps.pagination) {
      fetchPropertiesProps.pagination[k] = Number(v);
    }

    if (k === 'itemsPerPage' && fetchPropertiesProps.pagination) {
      fetchPropertiesProps.pagination[k] = Number(
        v
      ) as PaginationProperties['itemsPerPage'];
    }
  });

  return fetchPropertiesProps;
}
