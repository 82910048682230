import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Arrow(props: SvgIconProps) {
  return (
    <SvgIcon
      width="8"
      height="9.155"
      viewBox="0 0 8 9.155"
      fill="currentColor"
      {...props}
    >
      <g>
        <path
          d="M40.248,3.872,36.433.056a.191.191,0,0,0-.27,0l-3.8,3.816a.191.191,0,0,0,.135.325h2.1V8.964a.191.191,0,0,0,.191.191h3.051a.191.191,0,0,0,.191-.191V4.2h2.089a.191.191,0,0,0,.135-.326Z"
          transform="translate(-32.304 0)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
