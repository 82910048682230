import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      display: 'grid',
      grid: `auto / repeat(auto-fit, minmax(${theme.spacing(20)}px, 1fr))`,
      columnGap: theme.spacing(1),
      rowGap: theme.spacing(0.5),
    },
  })
);

export default useStyles;
