import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

interface ComponentProps {
  title?: string;
  comment?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    specialBox: {
      display: 'flex',
      flexDirection: 'column', //change into row if like
      alignItems: 'flex-start',
      width: theme.spacing(28),
      margin: theme.spacing(2),
      borderRadius: 60,
      // border: `1px solid ${theme.palette.primary.main}`,

      cursor: 'pointer',
      backgroundColor: `${theme.palette.primary.main}`,
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(22),
      },
      '& > div': {
        [theme.breakpoints.down('sm')]: {
          padding: '10px 16px',
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '16px 0',
      },
    },

    specialBoxComment: {
      color: '#FFF',
      textDecoration: 'none',
      fontSize: '1.25rem',
    },
  })
);

const SpecialBox = ({ comment }: ComponentProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.specialBox}>
      <Box
        p={2}
        display="flex"
        alignItems="center"
        width="100%"
        flexDirection="column"
      >
        <Typography className={classes.specialBoxComment} align="center">
          {comment}
        </Typography>
      </Box>
    </Box>
  );
};

export default SpecialBox;
