import * as React from 'react';
import { ViewCompactOutlined, ViewQuiltOutlined } from '@material-ui/icons';
import { Button } from '../../../../atoms';
import { PropertyListMobileViewMode } from '../../../../pages/properties/PropertyListPage';

export interface ViewSwitchButtonProps {
  viewMode: PropertyListMobileViewMode;
  setViewMode: (newViewMode: PropertyListMobileViewMode) => void;
}

export default function ViewSwitchButton({
  viewMode,
  setViewMode,
}: ViewSwitchButtonProps) {
  const handleClick = () => {
    if (viewMode === PropertyListMobileViewMode.NARROW) {
      setViewMode(PropertyListMobileViewMode.WIDE);
    } else {
      setViewMode(PropertyListMobileViewMode.NARROW);
    }
  };

  return (
    <Button
      variant="outlined"
      aria-label="toggle mobile view mode"
      onClick={handleClick}
    >
      {viewMode === PropertyListMobileViewMode.NARROW ? (
        <ViewQuiltOutlined color="inherit" />
      ) : (
        <ViewCompactOutlined color="inherit" />
      )}
    </Button>
  );
}
