import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import useStyles from './styles';
import clsx from 'clsx';
import { getUrlSearchString } from '../../../utils/dom';
import { parseQueryParamsString } from '../../../utils/url';

export interface BreadcrumbsProps {
  className?: string;
}

export default function BreadcrumbsComponent(props: BreadcrumbsProps) {
  const classes = useStyles();
  const search = getUrlSearchString(true);
  const searchData: Record<string, string> = search
    ? parseQueryParamsString(search)
    : {};

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={clsx(classes.breadcrumb, props.className)}
    >
      <Link color="inherit" href="/">
        Home
      </Link>
      <Typography color="textPrimary">{searchData.address}</Typography>
    </Breadcrumbs>
  );
}
