import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  SvgIconProps,
  Theme,
} from '@material-ui/core';
import { Typography } from '../../../atoms';
import {
  getNearbyPlaceDistanceText,
  GetNearbyPlaceDistanceTextConfig,
} from '../../../../utils/property';
import { NearbyPlace } from '../../../../models/nearbyPlaces';
import TrainIcon from '@material-ui/icons/Train';
import useScreenType from '../../../../utils/dom/useScreenType';
import { ScreenType } from '../../../../config';
import clsx from 'clsx';

// ========== TYPES ========== //

export interface NearbyPlacesProps {
  places: NearbyPlace[];
  icon: React.FunctionComponent<SvgIconProps>;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nearbyPlacesSubSectionCtn: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 1.3,
    },
    nearbyPlacesSubSectionCtnDesktop: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      lineHeight: 1.3,
    },

    nearbyPlace: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 4,
    },
    textGrey: {
      color: theme.palette.grey['500'],
      width: 50,
      marginLeft: 4,
    },

    name: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

// ========== COMPONENT ========== //

const distanceTextConfig: GetNearbyPlaceDistanceTextConfig = {
  distanceUnit: 'miles',
  distanceUnitLabel: 'mi',
};

export default function NearbyPlaces({
  icon: Icon,
  places,
}: NearbyPlacesProps) {
  const classes = useStyles();
  const screenType = useScreenType();

  const placeTexts = places.map((place, i) => {
    const placeName = place.place_data.name;
    const placeDistance = getNearbyPlaceDistanceText(
      place.place_distance_m ?? 0,
      distanceTextConfig
    );

    const connectingComma = i === places.length - 1 ? '' : ', ';

    return screenType === ScreenType.MOBILE ? (
      <Typography
        variant="subtitle1"
        key={place.place_id}
        className={classes.nearbyPlace}
      >
        <Icon />
        <span className={classes.name} title={placeName}>
          {placeName}
        </span>
        <span className={classes.textGrey}>{` | ${placeDistance}`}</span>
      </Typography>
    ) : (
      <span key={place.place_id}>
        <span>{placeName} </span>
        <span className={classes.textGrey}>{` | ${placeDistance}`}</span>
        <span>{connectingComma}</span>
      </span>
    );
  });

  return (
    <Box
      className={clsx(
        screenType === ScreenType.MOBILE
          ? classes.nearbyPlacesSubSectionCtn
          : classes.nearbyPlacesSubSectionCtnDesktop
      )}
    >
      {screenType === ScreenType.MOBILE ? (
        placeTexts
      ) : (
        <>
          <Icon />
          <Typography variant="subtitle1">{placeTexts}</Typography>
        </>
      )}
    </Box>
  );
}
