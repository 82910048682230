import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      padding: 0,
    },
    whyTitle: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      color: theme.palette.gray.contrastText,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    decorationNone: {
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    row: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  })
);
