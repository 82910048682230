import * as React from 'react';
import { Typography } from '../../../atoms';

export interface PriceTextProps {
  formattedPrice: string;
}

export default function PriceText({ formattedPrice }: PriceTextProps) {
  return (
    <Typography>
      From{' '}
      <Typography
        variant="h5"
        component="span"
        color="primary"
        fontWeight="bold"
      >
        {formattedPrice}
      </Typography>
      {' /month'}
    </Typography>
  );
}
