import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      color: theme.palette.primary.main,
    },

    arrowsCtn: {
      position: 'relative',
      height: '100%',
      padding: theme.spacing(0.5, 1),
    },

    arrowUp: {
      position: 'absolute',
      bottom: -theme.spacing(0.5),
      left: 0,
    },

    arrowDown: {
      position: 'absolute',
      top: -theme.spacing(0.5),
      left: 0,
    },
  })
);

export default useStyles;
