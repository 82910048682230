import {
  MediaEntries,
  ImageData,
  MediaEntry,
  VideoData,
  MediaData,
} from '../../../../../api/property/types';
import {
  DevelopmentAttribute,
  DevelopmentAttributeGroup,
  EpcRating,
  FlatAttribute,
  FlatAttributeGroup,
} from '../../../../../api/property/models';
import { OrgFileCategory } from '../../../../../api/orgs/types';

export const epcColors: { [key in EpcRating]: string } = {
  A: '#0f853e',
  B: '#2e9f2a',
  C: '#9fcb3a',
  D: '#fff301',
  E: '#fab31b',
  F: '#ee6725',
  G: '#ee6725',
};

export const FlatAttributeGroups: Record<FlatAttributeGroup, FlatAttribute[]> =
  {
    Facilities: ['fttr_kitchen_type', 'fttr_balcony'],
    Furniture: [
      'fttr_bed_type',
      'fttr_built_in_wardrobe',
      'fttr_drawers',
      'fttr_sofa',
      'fttr_coffee_table',
      'fttr_dining_table_and_chairs',
      'fttr_armchair',
    ],
    Appliance: [
      'fttr_tv',
      'fttr_hob_type',
      'fttr_fridge',
      'fttr_oven',
      'fttr_microwave',
      'fttr_dishwasher',
      'fttr_washing_machine',
      'fttr_dryer',
      'fttr_smart_meter',
    ],
    FeesAndCosts: ['fttr_short_rent'],
    Neighbourhood: [
      'fttr_pet_friendly',
      'fttr_student_friendly',
      'fttr_family_friendly',
    ],
  };

export const FlatAttributeGroupNames: {
  [key in FlatAttributeGroup]: string;
} = {
  Facilities: 'Facilities',
  Furniture: 'Furniture',
  Appliance: 'Appliance',
  FeesAndCosts: 'Fees & Costs',
  Neighbourhood: 'Neighbourhood',
};

export const DevelopmentAttributeGroups: {
  [key in DevelopmentAttributeGroup]: DevelopmentAttribute[];
} = {
  Facilities: [
    'attr_concierge',
    'attr_parcel',
    'attr_onsite_maintenance',
    'attr_bike_storage',
    'attr_parking',
    'attr_lift',
    'attr_wifi',
    'attr_roof_terrace',
    'attr_communal_garden',
    'attr_furnished',
    'attr_epc',
    'attr_dining_room',
    'attr_bar',
    'attr_guest_suites',
    'attr_heating',
    'attr_hot_water',
    'attr_bathroom_type',
  ],
  Amenities: [
    'attr_gym',
    'attr_pool',
    'attr_laundry',
    'attr_common_area',
    'attr_entertainment_area',
    'attr_cinema',
    'attr_work_area',
    'attr_library',
    'attr_social_events',
    'attr_usb_port',
    'attr_swimming_pool',
  ],
  SecurityAndSafety: [
    'attr_controlled_access',
    'attr_cctv',
    'attr_smoke_alarm',
    'attr_video_door_entry',
    'attr_onsite_management',
  ],
  FeesAndCosts: [
    'min_term',
    'max_term',
    'deposit_term',
    'notice_period',
    'zero_deposit',
  ],
};

export const developmentAttributeGroupNames: {
  [key in DevelopmentAttributeGroup]: string;
} = {
  Facilities: 'Facilities',
  Amenities: 'Amenities',
  SecurityAndSafety: 'Security & Safety',
  FeesAndCosts: 'Fees & Costs',
};

export enum PropertyAttributeGroup {
  DEVELOPMENT_FACILITIES = 'Facilities',
  DEVELOPMENT_AMENITIES = 'Amenities',
  DEVELOPMENT_ATMOSPHERE = 'Atmosphere',
  DEVELOPMENT_SECURITY = 'Security & Safety',
}

export const getMediaEntries = (
  images: ImageData[] | undefined,
  videos: VideoData[] | undefined
): MediaEntries => {
  const videoEntries: MediaEntries = [];
  videos?.map(function ({ video, thumbnail }) {
    const videoImage = {
      ...thumbnail,
      ...{ src_video_url: video.src_url, category: video.category },
    };
    videoEntries.push([
      thumbnail?.orgimages_id as string,
      videoImage as MediaData,
    ]);
  });

  const allImages = (images ? Object.entries(images) : []) as MediaEntries;
  const imageEntries: MediaEntries = [];

  for (const category of Object.values(OrgFileCategory)) {
    const filteredMediaEntries = filterMediaEntriesByCategory(
      allImages,
      category
    );

    imageEntries.push(...filteredMediaEntries);
  }

  return [...videoEntries, ...imageEntries];
};

export function extractExtensionFromName(fileName: string): string {
  const [_, extension]: string[] = fileName.split('.');
  return extension;
}

export function getImageUrl(imageData: ImageData): string {
  return imageData.src_url;
}

export const getCategoryName = (name: OrgFileCategory): string => {
  if (name === OrgFileCategory.DEVELOPMENT_VIDEO) {
    return 'VIDEO';
  }

  if (name === OrgFileCategory.FLAT_IMAGE) {
    return 'PROPERTY IMAGE';
  }

  return name.replace(/_/g, ' ');
};

export const filterMediaEntriesByCategory = (
  mediaEntries: MediaEntries,
  category: OrgFileCategory | 'all'
): MediaEntries => {
  // Each entry is an array of a string key and the data value
  return mediaEntries.filter(([_, mediaData]: MediaEntry) => {
    return mediaData.category === category;
  });
};
