import { API_HOST } from '../endpoints';

export const DISTANCE_FROM_LOCATION_CAP = 64;

export const propertyUrls = {
  // Retrieve all images related to a property (development + flats)
  GET_PROPERTY_IMAGES: (developmentId: number) =>
    `${API_HOST}/api/property/${developmentId}/allimages`,

  // Retrieve a property's thumbnail image. If no thumbnail image is set, will
  // return the first image.
  GET_PROPERTY_THUMBNAIL: (developmentId: number) =>
    `${API_HOST}/api/property/${developmentId}/thumbnailimage`,

  // Retrieve all videos related to a property (development + flats)
  GET_PROPERTY_VIDEOS: (developmentId: number) =>
    `${API_HOST}/api/property/${developmentId}/allvideos`,
};
