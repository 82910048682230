import * as React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Typography } from '../../../../atoms';

export interface MultiStringFilterProps {
  values: Array<{ value: string; label?: string }>;
  selectedValues: string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MultiStringFilter({
  values,
  selectedValues,
  onChange,
}: MultiStringFilterProps) {
  return (
    <>
      {values.map(({ value, label }) => (
        <FormControlLabel
          key={value}
          control={
            <Checkbox
              color="primary"
              size="small"
              disableRipple
              name={value}
              checked={selectedValues.includes(value)}
              onChange={onChange}
            />
          }
          label={<Typography variant="body2">{label ?? value}</Typography>}
        />
      ))}
    </>
  );
}
