import * as React from 'react';
import { Box } from '@material-ui/core';
import { AddressAutocomplete, AddressAutocompleteProps } from '../../molecules';
import { Button, Typography } from '../../atoms';
import useStyles from './SearchBar.styles';

export interface SearchBarProps {
  // This function triggers either when the search button is clicked, or when
  // the "Enter" key is hit on the search input.
  search: () => void;

  addressAutocompleteProps: Omit<
    AddressAutocompleteProps,
    'action' | 'noRoundedCorners'
  >;

  hasSearchButton?: boolean;
}

export default function SearchBar({
  hasSearchButton,
  search,
  addressAutocompleteProps,
}: SearchBarProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn} data-testid="search-bar">
      <AddressAutocomplete
        action={search}
        noRoundedCorners={!!hasSearchButton}
        {...addressAutocompleteProps}
      />
      {hasSearchButton && (
        <Button
          variant="contained"
          color="primary"
          className={classes.searchBtn}
          onClick={search}
        >
          <Typography variant="h6">Search</Typography>
        </Button>
      )}
    </Box>
  );
}
