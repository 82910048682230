import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useProperties from '../../../api/property/useProperties/useProperties';
import { ConnectedPropertiesSearchForm } from '../../widgets';
import SEO from '../../atoms/SEO';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import PropertyList from './PropertyList/PropertyList';
import RecentlyViewed from './RecentlyViewed';
import PropertyListFooter from './Footer/PropertyListFooter';
import useStyles from './PropertyListPage.styles';
import getFetchPropertiesPropsFromUrl from '../../../utils/property/getFetchPropertiesPropsFromUrl';
import useScreenType from '../../../utils/dom/useScreenType';
import ViewMap from './ViewMap';
import { getUrlSearchString } from '../../../utils/dom';
import { APP_ROUTES } from '../../../config/app';
import useAutocompleteServices from '../../../api/googleMaps/gcpPlaces/hooks/useAutocompleteServices';
import { ScreenType } from '../../../config';
import { PropertiesSearchFormProps } from '../../organisms';
import { CustomVariables } from '../../../utils/marketing/variables';
import { gtmDataLayerPropertiesUserSearchPush } from '../../../utils/marketing/eventPushes/gtmDataLayerProperties';
import { AdUnit } from '../../atoms';
import AdUnitContainer from './AdUnitContainer/AdUnitContainer';
import { getRangeArray } from '../../../utils/array';

export enum PropertyListMobileViewMode {
  // Narrow means the property photo doesn't take up the whole screen width.
  // This is the default mode as it's similar to the desktop view.
  NARROW,

  // Wide means the property photo takes up the whole screen width.
  WIDE,
}

type PropertiesSearchFormShowButtonProp = keyof Pick<
  PropertiesSearchFormProps,
  | 'showSortRecommendedBtn'
  | 'showSortPriceBtn'
  | 'showSearchRadiusFilter'
  | 'showBedroomsFilter'
  | 'showPriceFilter'
  | 'showFurnishingFilter'
  | 'showPetFriendlyFilter'
  | 'showZeroDepositFilter'
  | 'showMoreFilter'
  | 'showViewSwitch'
>;

// This object contains rules with regards to showing which button is shown on
// which screen type.
const propertiesSearchFormShowButtonPropsRules: Record<
  ScreenType,
  Array<PropertiesSearchFormShowButtonProp>
> = {
  [ScreenType.MOBILE]: [
    'showSortRecommendedBtn',
    'showSortPriceBtn',
    'showViewSwitch',
    'showMoreFilter',
  ],
  [ScreenType.TABLET_PORTRAIT]: [
    'showSortRecommendedBtn',
    'showSortPriceBtn',
    'showSearchRadiusFilter',
    'showViewSwitch',
    'showMoreFilter',
  ],
  [ScreenType.TABLET_LANDSCAPE]: [
    'showSearchRadiusFilter',
    'showBedroomsFilter',
    'showPriceFilter',
    'showPetFriendlyFilter',
    'showMoreFilter',
  ],
  [ScreenType.DESKTOP]: [
    'showSearchRadiusFilter',
    'showBedroomsFilter',
    'showPriceFilter',
    'showPetFriendlyFilter',
    'showMoreFilter',
  ],
};

// This creates a key-value object, with each key being a "showButtonX" prop
// name, and each value being true. The intention is to spread the returned
// object into <PropertyListPage />.
function getButtonsToShowProps(screenType: ScreenType) {
  return Object.values(
    propertiesSearchFormShowButtonPropsRules[screenType]
  ).reduce<Partial<Record<PropertiesSearchFormShowButtonProp, true>>>(
    (acc, cur) => {
      acc[cur] = true;
      return acc;
    },
    {}
  );
}

// Amount of ads appear in sidebar.
const AD_APPEAR_IN_SIDEBAR_THRESHOLD = 3;
// Amount of ads appear in sidebar when there is no property returned.
const AD_APPEAR_IN_SIDEBAR_NO_PROPERTY_THRESHOLD = 2;

const adUnitSlotIds: string[] = [
  process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_LIST_SIDEBAR_01_SLOT_ID ?? '',
  process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_LIST_SIDEBAR_02_SLOT_ID ?? '',
  process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_LIST_SIDEBAR_03_SLOT_ID ?? '',
  process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_LIST_SIDEBAR_04_SLOT_ID ?? '',
];

export default function PropertyListPage({ location }: RouteComponentProps) {
  const fetchPropertiesProps = getFetchPropertiesPropsFromUrl({
    search: location?.search ?? '',
  });

  const classes = useStyles();

  const { autocompleteService, placesService } = useAutocompleteServices({
    googleMapsAttributeElementId: 'google-maps-att-el',
  });

  const screenType = useScreenType();

  const buttonsToShowProps = getButtonsToShowProps(screenType);

  const defaultPropertyListMobileViewMode =
    screenType === ScreenType.MOBILE
      ? PropertyListMobileViewMode.WIDE
      : PropertyListMobileViewMode.NARROW;

  const [propertyListMobileViewMode, setPropertyListMobileViewMode] =
    React.useState(defaultPropertyListMobileViewMode);

  const {
    data: propertiesData,
    status: propertiesStatus,
    error: propertiesError,
  } = useProperties({
    fetchProps: fetchPropertiesProps,
    queryConfig: {
      enabled: !!fetchPropertiesProps.address,
    },
  });

  React.useEffect(() => {
    setPropertyListMobileViewMode(defaultPropertyListMobileViewMode);
  }, [screenType]);

  React.useEffect(() => {
    if (propertiesStatus !== 'loading') {
      const searchUrl = window.location.search;
      gtmDataLayerPropertiesUserSearchPush({
        [CustomVariables.SEARCH_URL]: searchUrl,
      });
    }
  }, [propertiesStatus]);

  const sidebarAdsAmount =
    (propertiesData?.data.length ?? 0) > 0
      ? AD_APPEAR_IN_SIDEBAR_THRESHOLD
      : AD_APPEAR_IN_SIDEBAR_NO_PROPERTY_THRESHOLD;

  return (
    <>
      <SEO
        title="Properties"
        description="Find build to rent properties in the UK"
        showAds
      />
      <Box className={classes.propertyListPageCtn}>
        <Container className={classes.container}>
          {/* BREADCRUMBS */}

          <Breadcrumbs className={classes.showDesktop} />

          {/* SEARCH ADDRESS */}

          <Box className={clsx(classes.title, classes.showDesktop)} pb={5}>
            <Typography variant="h4">{fetchPropertiesProps.address}</Typography>
          </Box>

          {/* PROPERTIES SEARCH FORM */}

          <Box className={classes.propertiesSearchFormCtn}>
            <ConnectedPropertiesSearchForm
              autocompleteService={autocompleteService}
              placesService={placesService}
              search={location?.search ?? ''}
              redirectIfSearchAddressIsEmpty
              redirectPathname={APP_ROUTES.properties}
              hasSearchButton
              viewMode={propertyListMobileViewMode}
              setViewMode={setPropertyListMobileViewMode}
              {...buttonsToShowProps}
            />
          </Box>

          {/* PROPERTY LIST */}

          <Grid container className={clsx(classes.row, classes.propertiesCtn)}>
            <Grid xs={12} sm={12} md={9} className={classes.p10} item>
              <Box className={classes.wrapper}>
                {propertiesStatus === 'success' && propertiesData ? (
                  <PropertyList
                    properties={propertiesData.data}
                    total={propertiesData.meta.total}
                    propertyListMobileViewMode={propertyListMobileViewMode}
                  />
                ) : propertiesStatus === 'loading' ? (
                  <Typography>Loading...</Typography>
                ) : propertiesStatus === 'error' ? (
                  <Typography color="error">
                    {propertiesError
                      ? propertiesError.message
                      : 'Unknown error'}
                  </Typography>
                ) : null}
              </Box>
            </Grid>

            {/* OTHER PROPERTIES FUNCTIONS & INFORMATION */}

            <Grid
              xs={12}
              sm={12}
              md={3}
              className={clsx(classes.p10, classes.gridItem)}
              item
            >
              <Box className={classes.wrapper} display="flex">
                {propertiesStatus === 'success' && propertiesData ? (
                  <Box
                    className={classes.pt100}
                    display="flex"
                    flexDirection="column"
                  >
                    <ViewMap search={getUrlSearchString()} />

                    {/* TODO: re-enable when this component goes in */}
                    {/*<AverageRentalPricesComponent />*/}
                    <RecentlyViewed />

                    <Box className={classes.ads}>
                      {adUnitSlotIds.slice(0, sidebarAdsAmount).map((id) => (
                        <Box className={classes.adsItem} key={id}>
                          <AdUnitContainer>
                            <AdUnit adSlot={id} />
                          </AdUnitContainer>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : propertiesStatus === 'loading' ? (
                  <Typography>Loading...</Typography>
                ) : propertiesStatus === 'error' ? (
                  <Typography color="error">Error...</Typography>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* INFORMATION FOOTER */}

      <PropertyListFooter />
    </>
  );
}
