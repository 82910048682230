import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { width?: number | string }>(
  (theme: Theme) =>
    createStyles({
      popper: {
        width: ({ width }) => width ?? undefined,
        padding: theme.spacing(1),
        transform: `translateY(${theme.spacing(1.5)}px)`,
      },
    })
);

export default useStyles;
