import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      height: 56,
    },

    searchBtn: {
      width: theme.spacing(15),
      borderRadius: 0,
      boxShadow: 'none',
    },
  })
);

export default useStyles;
