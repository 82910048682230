import * as React from 'react';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  createStyles,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

interface ComponentProps extends SelectProps {
  name: string;
  label: string;
  value: string | number | string[] | number[];
  values: Array<string | number>;
  valuesLabels?: string[];
  selectedValuesLabels?: string[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    nativeLabel: {
      position: 'absolute',
      width: '100%',
      top: 23,
      height: 41,
      padding: '11px 0 11px 15px',
      pointerEvents: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      '& > span': {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '1rem',
      },
      '& > svg': {
        opacity: 0.5,
      },
    },
  })
);

export default function SelectFilter({
  name,
  label,
  value,
  values,
  valuesLabels,
  selectedValuesLabels,
  handleChange,
  ...rest
}: ComponentProps) {
  const classes = useStyles();
  const isMultipleNativeSelect = rest.native && rest.multiple;

  return (
    <FormControl>
      {isMultipleNativeSelect && (
        <span className={classes.nativeLabel}>
          <span>{selectedValuesLabels?.join(', ')}</span>
          <ArrowDropDown />
        </span>
      )}
      <InputLabel id={`${name}-select-label`} htmlFor={`${name}-select`} shrink>
        {label}
      </InputLabel>
      <Select
        labelId={`${name}-label`}
        id={`${name}-select`}
        name={name}
        value={value}
        onChange={handleChange}
        style={{
          color: isMultipleNativeSelect ? 'transparent' : 'inherit',
        }}
        {...rest}
      >
        {values.map((v, index) => {
          const label = valuesLabels ? valuesLabels[index] : v;
          return rest.native ? (
            <option key={v} value={v} title={label as string}>
              {label}
            </option>
          ) : (
            <MenuItem key={v} value={v} title={label as string}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
