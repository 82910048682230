import * as React from 'react';
import {
  FlatTypeForSearchPurpose,
  PrimitiveAttributesFilter,
} from '../../../api/property/types';
import { DevelopmentBaseFilter } from '../../../api/property/useProperties/types';
import { ScreenType } from '../../../config';
import {
  PropertiesPrimitiveAttributesFilters,
  SingleValueOnChangeFactory,
} from './types';

export enum Dropdown {
  SEARCH_RADIUS,
  BEDROOMS,
  PRICE,
  FURNISHING,
  PET_FRIENDLY,
  ZERO_DEPOSIT,
  MORE,
}

export interface ValueAndLabel {
  value: string;
  label?: string;
}

export const getSearchRadiusValues = (
  distanceUnit: 'metric' | 'imperial' = 'metric'
): Required<ValueAndLabel>[] => [
  { value: '250', label: 'Within ¼ km' },
  { value: '500', label: 'Within ½ km' },
  { value: '1000', label: 'Within 1 km' },
  { value: '3000', label: 'Within 3 km' },
  { value: '5000', label: 'Within 5 km' },
  { value: '10000', label: 'Within 10 km' },
];

export const getHeroSearchRadiusValues = (
  distanceUnit: 'metric' | 'imperial' = 'metric'
): Required<ValueAndLabel>[] => [
  { value: '15000', label: 'This area only' },
  ...getSearchRadiusValues(distanceUnit),
];

export const bedroomsValues: Required<ValueAndLabel>[] = [
  { value: `${FlatTypeForSearchPurpose.PRIVATE_STUDIO}`, label: 'Studio' },
  {
    value: `${FlatTypeForSearchPurpose.PRIVATE_ONE_BEDROOM}`,
    label: '1-bedroom',
  },
  {
    value: `${FlatTypeForSearchPurpose.PRIVATE_TWO_BEDROOM}`,
    label: '2-bedroom',
  },
  {
    value: `${FlatTypeForSearchPurpose.PRIVATE_THREE_BEDROOM}`,
    label: '3-bedroom',
  },
  {
    value: `${FlatTypeForSearchPurpose.PRIVATE_FOUR_BEDROOM}`,
    label: '4-bedroom',
  },
  { value: `${FlatTypeForSearchPurpose.SHARED}`, label: 'Private Room' },
  {
    value: `${FlatTypeForSearchPurpose.SHARED_ENSUITE}`,
    label: 'Private Ensuite Room',
  },
];

export const defaultRentRangeValues: [number, number] = [0, 30000];
export const defaultRentRangeStep = 50;

export const furnishingValues: ValueAndLabel[] = [
  { value: 'Furnished' },
  { value: 'Flexible' },
  { value: 'Unfurnished' },
];

export const hobTypeValues: ValueAndLabel[] = [
  { value: 'Induction Hob', label: 'Induction' },
  { value: 'Electric Hob', label: 'Electric' },
  { value: 'Gas Hob', label: 'Gas' },
  { value: 'Ceramic Hob', label: 'Ceramic' },
];

export const bedSizeValues: ValueAndLabel[] = [
  { value: 'single', label: 'Single' },
  { value: 'double', label: 'Double' },
  { value: 'king', label: 'King' },
  { value: 'flexible', label: 'Flexible' },
];

export const billsIncludedValues: ValueAndLabel[] = [
  { value: '4', label: 'TV license' },
  { value: '5', label: 'Wifi' },
  { value: '1', label: 'Water' },
  { value: '2', label: 'Electricity' },
  { value: '3', label: 'Gas' },
];

export const depositTermValues: ValueAndLabel[] = [
  { value: '1 month' },
  { value: '4 weeks' },
  { value: '5 weeks' },
  { value: '6 weeks' },
];

export const breakClauseValues: ValueAndLabel[] = [
  { value: 'Yes' },
  { value: 'No' },
];

export const noticePeriodValues: ValueAndLabel[] = [
  { value: 'Dependent on agreement' },
  { value: '14 days' },
  { value: '28 days' },
  { value: '1 month' },
  { value: '2 months' },
  { value: '3 months' },
];

export const getMoreFilterItemsPrimitiveAttributesBoolean = ({
  items,
  primitiveAttributesFilters,
  onChangeFactory,
}: {
  items: Array<{ label: string; key: keyof PrimitiveAttributesFilter }>;
  primitiveAttributesFilters: PropertiesPrimitiveAttributesFilters;
  onChangeFactory: SingleValueOnChangeFactory;
}): Array<{
  label: string;
  value: boolean | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> =>
  items.map(({ label, key }) => ({
    label,
    value: primitiveAttributesFilters[key] as boolean | undefined,
    onChange: onChangeFactory({
      filterName: key,
      underlyingValueType: 'boolean',
      isPrimitiveAttribute: true,
    }),
  }));

// Return true if the provided attribute value indicates that the attribute is
// being filtered (it's not in the default zero state).
export const isAttributeBeingFiltered = (
  attributeValue:
    | undefined
    | null
    | boolean
    | number
    | string
    | (string | number | boolean | null)[]
): boolean => {
  if (Array.isArray(attributeValue)) {
    return attributeValue.length > 0;
  }

  return !!attributeValue;
};

export interface MoreDropdownFiltered {
  propertiesFilters: DevelopmentBaseFilter;
  propertiesPrimitiveAttributesFilters: PropertiesPrimitiveAttributesFilters;
  screenType: ScreenType;
}

// Return true if any filter within the More+ dropdown is active
export const moreDropdownFiltered = ({
  propertiesFilters,
  propertiesPrimitiveAttributesFilters,
  screenType,
}: MoreDropdownFiltered): boolean => {
  // The More+ dropdown includes all primitive attribute filters
  let filtered = Object.values(propertiesPrimitiveAttributesFilters).some(
    (attributeValue) => {
      return isAttributeBeingFiltered(attributeValue);
    }
  );

  // On tablet (portrait) view, the More+ dropdown also includes the bedrooms
  // and price filters
  if (!filtered && screenType === ScreenType.TABLET_PORTRAIT) {
    const bedroomsFiltered = (propertiesFilters.flatType?.length ?? 0) > 0;

    const priceFiltered =
      (propertiesFilters.rentMin !== undefined &&
        propertiesFilters.rentMin > defaultRentRangeValues[0]) ||
      (propertiesFilters.rentMax !== undefined &&
        propertiesFilters.rentMax < defaultRentRangeValues[1]);

    filtered = bedroomsFiltered || priceFiltered;
  }

  // On mobile view, the More+ dropdown also includes the radius filter
  if (!filtered && screenType === ScreenType.MOBILE) {
    const searchRadiusValues = getSearchRadiusValues();

    filtered =
      propertiesFilters.radius !== undefined &&
      searchRadiusValues.some(
        ({ value }) => value === `${propertiesFilters.radius}`
      );
  }

  return filtered;
};
