import * as React from 'react';
import { FormControl, Input, InputLabel, InputProps } from '@material-ui/core';

interface ComponentProps extends InputProps {
  name: string;
  label: string;
  value: number | undefined;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function NumberFilter({
  name,
  label,
  value,
  handleChange,
  ...rest
}: ComponentProps) {
  return (
    <FormControl>
      <InputLabel id={`${name}-number-label`} htmlFor={`${name}-number`} shrink>
        {label}
      </InputLabel>
      <Input
        type="number"
        id={`${name}-number`}
        name={name}
        value={value}
        onInput={handleChange}
        {...rest}
      />
    </FormControl>
  );
}
