import * as React from 'react';
import {
  Box,
  Button,
  Tab,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Skeleton, TabContext, TabList, TabPanel } from '@material-ui/lab';
import {
  PlayArrow,
  Room as RoomIcon,
  Train as TrainIcon,
  ArrowRight as ArrowRightIcon,
} from '@material-ui/icons';
import useScreenType from '../../../utils/dom/useScreenType';
import { ScreenType } from '../../../config';
import cityImages from '../../../config/cityImageData/city-images.json';
import useStyles from './PopularPropertiesTabs.styles';
import Slider, { Settings as SliderSettings } from 'react-slick';
import usePromotedProperties from '../../../api/property/usePromotedProperties/usePromotedProperties';
import { Link, Typography, Video, VideoMIMEType } from '../../atoms';
import CloudinaryPicture from '../../atoms/CloudinaryPicture/CloudinaryPicture';
import useFetchPropertyThumbnail from '../../../api/property/useFetchPropertyThumbnail';
import {
  getNearbyPlaceDistanceText,
  GetNearbyPlaceDistanceTextConfig,
} from '../../../utils/property';
import { getAddressFromPropertyData } from '../../../utils/address';
import { formatMoney } from '../../../utils/number';
import { isVideoByExt } from '../../../utils/file';
import clsx from 'clsx';
import { getPropertyImageCloudinaryPictureSizeConfig } from '../../pages/properties/PropertyImage/propertyImageConfig';
import { PropertyListMobileViewMode } from '../../pages/properties/PropertyListPage';
import { extractExtensionFromName } from '../../pages/properties/single-view/utils';
import { AnalyticsPropertyCardProps } from '../../organisms/AnalyticsPropertyCard';
import ArrowIcon from '../../../images/icons/Arrow';

export interface PopularPropertiesTabsProps {
  showViewAllPropertiesButton?: boolean;
  cities: string[];
  rows?: number;
  analytics?: boolean;
}

export default function PopularPropertiesTabs({
  showViewAllPropertiesButton = true,
  cities,
  rows,
  analytics,
}: PopularPropertiesTabsProps) {
  const classes = useStyles();
  const [currentCity, setCurrentCity] = React.useState(cities[0]);
  const screenType = useScreenType();

  const cityLinks: Record<string, string> = {};
  cities.forEach((name) => {
    const city = cityImages.find((d) => d.name === name);

    if (city) {
      cityLinks[name] = city.link;
    }
  });

  return (
    <div className={classes.container}>
      <TabContext value={currentCity}>
        <TabList
          variant={screenType === ScreenType.MOBILE ? 'scrollable' : 'standard'}
          className={classes.listTab}
          value={currentCity}
          centered
          onChange={(_, city: string) => setCurrentCity(city)}
          textColor="primary"
          indicatorColor="primary"
        >
          {cities.map((city) => (
            <Tab
              key={city}
              className={classes.listItem}
              classes={{
                selected: classes.tabSelected,
              }}
              label={city}
              value={city}
            />
          ))}
        </TabList>
        {cities.map((city) => (
          <TabPanel
            classes={{
              root: classes.tabPanel,
            }}
            key={city}
            value={city}
          >
            <Box>
              <PopularPropertiesContainer
                city={city}
                rows={rows}
                analytics={analytics}
              />
            </Box>
            {showViewAllPropertiesButton && (
              <Box pt={'45px'} className={classes.center}>
                <Button
                  variant="contained"
                  className={classes.customBtn}
                  href={cityLinks[city]}
                >
                  View all properties in {city}
                </Button>
              </Box>
            )}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
}

interface PopularPropertiesContainerProps {
  city: string;
  rows?: number;
  analytics?: boolean;
}

function PopularPropertiesContainer({
  city,
  rows = 2,
  analytics,
}: PopularPropertiesContainerProps) {
  const theme = useTheme();
  const classes = useStyles();

  const {
    data: promotedPropertiesData,
    isLoading: promotedPropertiesIsLoading,
  } = usePromotedProperties({
    city,
  });

  const sliderSettings: SliderSettings = {
    slidesToShow: 1,
    rows,
    slidesPerRow: 3,
    infinite: false,
    arrows: false,
    className: classes.slick,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesPerRow: 1,
          rows: 1,
          variableWidth: true,
        },
      },
    ],
  };

  if (promotedPropertiesIsLoading) return <p>Loading...</p>;

  return (
    <Slider {...sliderSettings}>
      {promotedPropertiesData &&
        promotedPropertiesData.data.map(
          ({
            developmentId,
            title,
            address,
            postcode,
            developmentNearbyPlaces,
            flats,
          }) => (
            <PopularPropertyCard
              key={developmentId}
              developmentId={developmentId}
              title={title}
              address={address}
              postcode={postcode}
              developmentNearbyPlaces={developmentNearbyPlaces}
              rentPcm={flats[0]?.rentPcm ?? 0}
              analytics={analytics}
            />
          )
        )}
    </Slider>
  );
}

const distanceTextConfig: GetNearbyPlaceDistanceTextConfig = {
  distanceUnit: 'miles',
  distanceUnitLabel: 'mi',
};

const analyticsData: AnalyticsPropertyCardProps['analytics'] = {
  viewCount7day: 32,
  viewCountChange7day: 29,
  leadCount7day: 59,
  leadCountChange7day: -32,
  averageEngagementTime7day: 52,
  averageEngagementTimeChange7day: 16,
};

interface PopularPropertyCardProps {
  developmentId: number;
  title: string;
  address: string | null;
  postcode: string | null;
  rentPcm: number;
  developmentNearbyPlaces: {
    placeType: string;
    placeDistanceM: number;
    placeDatum: { name: string };
  }[];
  analytics?: boolean;
}

function PopularPropertyCard({
  developmentId,
  title,
  address,
  postcode,
  rentPcm,
  developmentNearbyPlaces,
  analytics,
}: PopularPropertyCardProps) {
  const classes = useStyles();

  const { data: developmentThumbnail } = useFetchPropertyThumbnail({
    developmentId,
  });

  const developmentThumbnailSrc = developmentThumbnail?.data?.src_url;

  const cloudinaryPictureConfig = getPropertyImageCloudinaryPictureSizeConfig(
    PropertyListMobileViewMode.NARROW
  );

  const {
    viewCount7day,
    viewCountChange7day,
    leadCount7day,
    leadCountChange7day,
    averageEngagementTime7day,
    averageEngagementTimeChange7day,
  } = analyticsData;

  return (
    <Box className={classes.propertyItem}>
      <Link to={`/properties/${developmentId}`} className={classes.border}>
        <Box className={classes.relative}>
          {developmentThumbnailSrc ? (
            isVideoByExt(
              extractExtensionFromName(
                developmentThumbnail?.data?.original_name ?? ''
              )
            ) ? (
              <Box height="100%">
                <Video
                  videoType={VideoMIMEType.MPEG4}
                  src={developmentThumbnailSrc}
                  hidePlayButton={true}
                  onClick={() => {
                    return;
                  }}
                />
                <Box className={classes.playIcon}>
                  <PlayArrow />
                </Box>
              </Box>
            ) : (
              <CloudinaryPicture
                imgSrc={developmentThumbnailSrc}
                imgAlt={title}
                config={cloudinaryPictureConfig}
                imgProps={{
                  height: '100%',
                  width: '100%',
                }}
              />
            )
          ) : (
            <Skeleton variant="rect" width="100%" height="100%" />
          )}
        </Box>
        <Box className={classes.info}>
          <Box
            className={clsx(
              classes.heading,
              analytics && classes.analyticsHeading
            )}
          >
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Typography className={classes.week}>
              From <b>{formatMoney(rentPcm, 'GBP', 0)}</b>/month
            </Typography>
          </Box>
          <Box pt={analytics ? 0 : 1}>
            <List component="nav" disablePadding={analytics}>
              <ListItem className={classes.desItem}>
                <ListItemIcon
                  className={clsx(
                    classes.widthIcon,
                    classes.roomIcon,
                    analytics && classes.analyticsRoomIcon
                  )}
                >
                  <RoomIcon />
                </ListItemIcon>
                <ListItemText
                  className={clsx(
                    classes.textDes,
                    analytics && classes.analyticsTextDesc
                  )}
                >
                  {getAddressFromPropertyData({
                    address: address ?? '',
                    postcode: postcode ?? '',
                  })}
                </ListItemText>
              </ListItem>
              {analytics ? (
                <Box className={classes.analytics}>
                  <Box className={classes.analyticItem}>
                    <Box display="flex" alignItems="center">
                      <ArrowRightIcon className={classes.arrowIcon} />
                      <Typography
                        className={classes.analyticText}
                        color="gray"
                        colorVariant="contrastText"
                      >
                        7-day views
                      </Typography>
                    </Box>
                    <Typography
                      className={classes.analyticText}
                      color="gray"
                      colorVariant="light1"
                    >
                      {viewCount7day} (
                      {viewCountChange7day && (
                        <>
                          {viewCountChange7day < 0 ? (
                            <ArrowIcon className={classes.downIcon} />
                          ) : (
                            <ArrowIcon className={classes.upIcon} />
                          )}
                          {Math.abs(viewCountChange7day)}%
                        </>
                      )}
                      )
                    </Typography>
                  </Box>
                  <Box className={classes.analyticItem}>
                    <Box display="flex" alignItems="center">
                      <ArrowRightIcon className={classes.arrowIcon} />
                      <Typography
                        className={classes.analyticText}
                        color="gray"
                        colorVariant="contrastText"
                      >
                        7-day leads
                      </Typography>
                    </Box>
                    <Typography
                      className={classes.analyticText}
                      color="gray"
                      colorVariant="light1"
                    >
                      {leadCount7day} (
                      {leadCountChange7day && (
                        <>
                          {leadCountChange7day < 0 ? (
                            <ArrowIcon className={classes.downIcon} />
                          ) : (
                            <ArrowIcon className={classes.upIcon} />
                          )}
                          {Math.abs(leadCountChange7day)}%
                        </>
                      )}
                      )
                    </Typography>
                  </Box>
                  <Box className={classes.analyticItem}>
                    <Box display="flex" alignItems="center">
                      <ArrowRightIcon className={classes.arrowIcon} />
                      <Typography
                        className={classes.analyticText}
                        color="gray"
                        colorVariant="contrastText"
                      >
                        Avg visit time
                      </Typography>
                    </Box>
                    <Typography
                      className={classes.analyticText}
                      color="gray"
                      colorVariant="light1"
                    >
                      {averageEngagementTime7day} (
                      {averageEngagementTimeChange7day && (
                        <>
                          {averageEngagementTimeChange7day < 0 ? (
                            <ArrowIcon className={classes.downIcon} />
                          ) : (
                            <ArrowIcon className={classes.upIcon} />
                          )}
                          {Math.abs(averageEngagementTimeChange7day)}%
                        </>
                      )}
                      )
                    </Typography>
                  </Box>
                </Box>
              ) : (
                developmentNearbyPlaces.map(
                  ({ placeDistanceM, placeDatum: { name } }) => (
                    <ListItem key={name} className={classes.desItem}>
                      <ListItemIcon
                        className={clsx(classes.widthIcon, classes.trainIcon)}
                      >
                        <TrainIcon />
                      </ListItemIcon>
                      <ListItemText className={classes.textDes}>
                        {name}{' '}
                        <span className={classes.colorLight}>
                          |{' '}
                          {getNearbyPlaceDistanceText(
                            placeDistanceM,
                            distanceTextConfig
                          )}
                        </span>
                      </ListItemText>
                    </ListItem>
                  )
                )
              )}
            </List>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
