import * as React from 'react';
import { Typography } from '../../../../atoms';
import { ButtonWithDropdown } from '../../../../molecules';

export interface FilterButtonWithDropdownProps {
  buttonText: string;
  buttonOnClick: () => void;
  dropdownOpen: boolean;

  // If true, will style the filter button differently to indicate that this
  // filter is being applied
  filtered?: boolean;

  children?: React.ReactNode;
}

function DropdownButtonTypography({
  open,
  filtered,
  children,
}: {
  open: boolean;
  filtered?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <Typography
      variant="body2"
      color={open || filtered ? 'primary' : undefined}
      fontWeight={open || filtered ? 'bold' : undefined}
    >
      {children}
    </Typography>
  );
}

export default function FilterButtonWithDropdown({
  buttonText,
  buttonOnClick,
  dropdownOpen,
  filtered,
  children,
}: FilterButtonWithDropdownProps) {
  return (
    <ButtonWithDropdown
      variant="outlined"
      dropdownProps={{
        open: dropdownOpen,
        handleClick: buttonOnClick,
        dropdownElement: <>{children}</>,
        modifiers: {
          flip: {
            enabled: false,
          },
        },
      }}
    >
      <DropdownButtonTypography filtered={filtered} open={dropdownOpen}>
        {buttonText}
      </DropdownButtonTypography>
    </ButtonWithDropdown>
  );
}
