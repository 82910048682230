import * as React from 'react';
import { Box } from '@material-ui/core';
import WhyRentWithUs from '../WhyRentWithUs';
import TalkToUs from '../../../organisms/TalkToUs';
import AdUnitContainer from '../AdUnitContainer/AdUnitContainer';
import { AdUnit } from '../../../atoms';

export default function PropertyListFooter() {
  return (
    <Box display="flex" flexDirection="column">
      <WhyRentWithUs isClassGray />
      <TalkToUs />
      <AdUnitContainer>
        <AdUnit
          adSlot={
            process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_LIST_FOOTER_01_SLOT_ID ??
            ''
          }
        />
      </AdUnitContainer>
      <AdUnitContainer>
        <AdUnit
          adSlot={
            process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_LIST_FOOTER_02_SLOT_ID ??
            ''
          }
        />
      </AdUnitContainer>
    </Box>
  );
}
