import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from '../../../atoms';
import { formatMoney } from '../../../../utils/number';

export interface FlatPricesProps {
  flatPrices?: Array<{ title: string; price: number }>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flatPricesCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
    },

    flatPriceRowCtn: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
    },
  })
);

export default function FlatPrices({ flatPrices }: FlatPricesProps) {
  const classes = useStyles();

  return (
    <Box className={classes.flatPricesCtn}>
      {flatPrices?.map(({ title, price }) => (
        <Box key={title} className={classes.flatPriceRowCtn}>
          <Typography variant="subtitle1" color="grey" colorVariant={500}>
            {title}
          </Typography>
          <Typography variant="subtitle1">
            {formatMoney(price, 'GBP', 0)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
