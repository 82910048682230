import { Box, Container, Typography } from '@material-ui/core';
import * as React from 'react';
import WhyRentWithUsContent, { SpecialStep } from './WhyRentWithUsContent';
import BestQuality from '../../../../images/iconsV2/large/Harmony/HA_4_bestquality.svg';
import TrustedLandLord from '../../../../images/iconsV2/large/Harmony/HA_5_trustedlandlord.svg';
import OnsiteManagement from '../../../../images/iconsV2/large/Harmony/HA_6_onsitemanagement.svg';
import useStyles from './styles';

interface ComponentProps {
  isClassGray?: boolean;
}

function WhyRentWithUs({ isClassGray }: ComponentProps) {
  const classes = useStyles();
  const steps: SpecialStep[] = [
    {
      header: 'High quality',
      body: 'New builds, high quality with regular maintenance',
      icon: BestQuality,
    },
    {
      header: 'Fully trusted landlords',
      body: 'Large-scale and professional, committed to customer satisfaction',
      icon: TrustedLandLord,
    },
    {
      header: 'Onsite management',
      body: 'All issues are sorted ASAP at no additional cost',
      icon: OnsiteManagement,
    },
  ];
  return (
    <Box
      p="50px 15px 80px 15px"
      width="100%"
      className={isClassGray ? classes.bgGray : ''}
    >
      <Container className={classes.container}>
        <Box pb={3}>
          <Typography variant="h4" className={classes.whyTitle}>
            Why Build To Rent
          </Typography>
        </Box>
        <Box
          display="flex"
          // flexWrap="wrap"
          justifyContent="center"
          alignContent="stretch"
        >
          <WhyRentWithUsContent steps={steps} />
        </Box>
      </Container>
    </Box>
  );
}

export default WhyRentWithUs;
