import * as React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import SvgIconImg from '../../../atoms/SvgIconImg/SvgIconImg';
import useStyles from './styles/WhyRentWithUsContent';

export interface SpecialStep {
  header: string;
  body?: string;
  icon?: string;
}

interface ComponentProps {
  steps: SpecialStep[];
}

const WhyRentWithUsContent = ({ steps }: ComponentProps) => {
  const classes = useStyles();

  const stepEls = steps.map(({ header, body, icon: Icon }) => {
    return (
      <Grid key={header} item className={classes.item} xs={12} sm={4}>
        <SvgIconImg
          src={Icon ?? ''}
          alt="service 1"
          className={classes.iconSize}
        />
        <Box pl={'10px'} pr={'15px'}>
          <Typography variant="h6" className={classes.title}>
            {header}
          </Typography>
          <Typography className={classes.des}>{body}</Typography>
        </Box>
      </Grid>
    );
  });

  return (
    <Box className={classes.root} bgcolor="transparent">
      <Grid container spacing={3}>
        {stepEls}
      </Grid>
    </Box>
  );
};

export default WhyRentWithUsContent;
