import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sliderCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),

      width: theme.spacing(30),
      padding: theme.spacing(1),
    },

    inputsCtn: {
      display: 'grid',
      grid: 'auto / 1fr auto 1fr',
      gap: theme.spacing(1),
    },

    connector: {
      transform: `translateY(${theme.spacing(0.5)}px)`,
    },

    controlPanelCtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(1),
    },
  })
);

export default useStyles;
