import * as React from 'react';
import { Button, Typography } from '../../../../atoms';
import { SetSortType } from '../../types';
import { PropertiesSearchSortType } from '../../../../../api/property/types';

export interface SortByRecommendedButtonProps {
  sortType: PropertiesSearchSortType;
  setSortType: SetSortType;
}

export default function SortByRecommendedButton({
  sortType,
  setSortType,
}: SortByRecommendedButtonProps) {
  const isActive = sortType === PropertiesSearchSortType.RECOMMENDED;

  const handleClick = () => {
    setSortType(PropertiesSearchSortType.RECOMMENDED);
  };

  return (
    <Button variant="outlined" onClick={handleClick}>
      <Typography
        variant="body2"
        color={isActive ? 'primary' : undefined}
        fontWeight={isActive ? 'bold' : undefined}
      >
        Recommended
      </Typography>
    </Button>
  );
}
