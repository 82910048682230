import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  BrokenImage,
  CameraAlt,
  KeyboardArrowRight,
  PlayArrow,
} from '@material-ui/icons';
import { useSwipeable } from 'react-swipeable';
import clsx from 'clsx';
import useStyles from '../styles/ImageCarousel';
import { getPropertyImageCloudinaryPictureSizeConfig } from '../PropertyImage/propertyImageConfig';
import CloudinaryPicture from '../../../atoms/CloudinaryPicture/CloudinaryPicture';
import { PropertyListMobileViewMode } from '../PropertyListPage';
import { Link } from '../../../atoms';
import { ImageData } from '../../../../api/property/types';

export interface ImageCarouselProps {
  imgHeight?: string | number;
  imgSrcs: ImageData[];
  justAdded: boolean;
  imgCount?: number;
  mobileViewMode?: PropertyListMobileViewMode;
  onImageClick?: () => void;
  imgLink?: string;
}

interface CarouselBtnProps {
  className?: string;
  mobileViewMode?: PropertyListMobileViewMode;
  onClick?: () => void;
}

const JustAddedTag = () => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.tag, classes.justAddedTagContainer)}>
      <Typography>Just added</Typography>
    </Box>
  );
};

const ImgCountTag = ({ count }: { count: number }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.tag, classes.imgCountTagContainer)}>
      <CameraAlt />
      <Typography>{count}</Typography>
    </Box>
  );
};

const CarouselBtn = ({
  mobileViewMode,
  className,
  onClick,
}: CarouselBtnProps) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(
        className,
        classes.tag,
        mobileViewMode === PropertyListMobileViewMode.WIDE && classes.show
      )}
      onClick={onClick}
    >
      <KeyboardArrowRight />
    </Box>
  );
};

export default function ImageCarousel({
  imgHeight,
  justAdded,
  imgSrcs,
  imgCount,
  mobileViewMode,
  onImageClick,
  imgLink,
}: ImageCarouselProps) {
  const classes = useStyles();

  const [curImg, setCurImg] = useState(0);

  const maxImgIndex = imgSrcs.length - 1;

  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        setCurImg(curImg === maxImgIndex ? 0 : curImg + 1);
      } else if (eventData.dir === 'Right') {
        setCurImg(curImg === 0 ? maxImgIndex : curImg - 1);
      }
    },
  });

  const handlePrevImgBtnClick = () => {
    setCurImg(curImg === 0 ? maxImgIndex : curImg - 1);
  };

  const handleNextImgBtnClick = () => {
    setCurImg(curImg === maxImgIndex ? 0 : curImg + 1);
  };

  if (imgSrcs.length === 0) {
    return (
      <Box
        className={classes.emptyImg}
        style={{
          height: imgHeight ?? 200,
        }}
      >
        <BrokenImage />
      </Box>
    );
  }

  const cloudinaryPictureConfig = getPropertyImageCloudinaryPictureSizeConfig(
    mobileViewMode ?? PropertyListMobileViewMode.NARROW
  );

  const imageEl = (
    <CloudinaryPicture
      imgSrc={imgSrcs[curImg].src_url}
      imgAlt="property image"
      config={cloudinaryPictureConfig}
      imgProps={{
        className: classes.propertyImg,
        height: imgHeight ?? 200,
        onClick: onImageClick,
      }}
    />
  );

  const isVideo = imgSrcs[curImg].src_video_url ?? false;

  return (
    <Box {...swipeHandlers} className={classes.mainContainer}>
      {imgLink ? (
        <Link fullSize noColor to={imgLink}>
          {imageEl}
        </Link>
      ) : (
        imageEl
      )}
      {isVideo && (
        <Box className={classes.playIcon}>
          <PlayArrow />
        </Box>
      )}
      {justAdded && <JustAddedTag />}
      {imgCount && <ImgCountTag count={imgCount} />}
      <CarouselBtn
        className={classes.carouselPrevBtn}
        onClick={handlePrevImgBtnClick}
        mobileViewMode={mobileViewMode}
      />
      <CarouselBtn
        className={classes.carouselNextBtn}
        onClick={handleNextImgBtnClick}
        mobileViewMode={mobileViewMode}
      />
    </Box>
  );
}
