import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { PropertyV2 } from '../../../../api/property/types';
import { getUrlSearchString, navigate } from '../../../../utils/dom';
import {
  combineQueryParams,
  parseQueryParamsString,
} from '../../../../utils/url';
import { defaultFetchPropertiesProps } from '../../../../utils/property/getFetchPropertiesPropsFromUrl';
import { getRangeArray } from '../../../../utils/array';

import { AdUnit } from '../../../atoms';
import { Pagination } from '../../../molecules';
import AdUnitContainer from '../AdUnitContainer/AdUnitContainer';
import PropertyCard from '../PropertyCard/PropertyCard';
import { PropertyListMobileViewMode } from '../PropertyListPage';
import SelectRecommended from '../SelectRecommended';

import useStyles from './PropertyList.styles';

export interface PropertyListProps {
  properties: PropertyV2[];
  total: number;
  propertyListMobileViewMode: PropertyListMobileViewMode;
}

function getPropertyCards({
  properties,
  fromIndex,
  toIndex,
  propertyListMobileViewMode,
}: {
  properties: PropertyV2[];
  propertyListMobileViewMode: PropertyListMobileViewMode;
  fromIndex: number;
  toIndex: number;
}) {
  const range = getRangeArray(fromIndex, toIndex);

  return range.map((index) => {
    const property = properties[index];
    return (
      <PropertyCard
        key={`${property.development_id}${property.flat_id}`}
        property={property}
        propertyListMobileViewMode={propertyListMobileViewMode}
      />
    );
  });
}

// If the amount of properties returned is higher than or equal to this number,
// the ad will be shown in the middle of the page. Otherwise, it will be shown
// at the bottom of the page.
const AD_APPEAR_IN_MIDDLE_THRESHOLD = 5;

export default function PropertyList({
  properties,
  total,
  propertyListMobileViewMode,
}: PropertyListProps) {
  const classes = useStyles();
  const search = getUrlSearchString(true);
  let searchData: Record<string, string> = {};
  if (search) {
    searchData = parseQueryParamsString(search);
  }

  // info page
  const page = searchData.page ? Number(searchData.page) : 1;
  const itemsPerPage = searchData.itemsPerPage
    ? +searchData.itemsPerPage
    : defaultFetchPropertiesProps.pagination.itemsPerPage;
  const totalPage = Math.ceil(total / itemsPerPage);

  function handlePagination(action: string, pageAction?: number) {
    let urlComponents = {};
    let numberPage: number = page;
    switch (action) {
      case 'next':
        numberPage = numberPage + 1;
        break;
      case 'prev':
        numberPage = numberPage - 1;
        break;
      case 'start':
        numberPage = 1;
        break;
      case 'end':
        numberPage = totalPage;
        break;
      case 'page_action':
        if (pageAction) numberPage = pageAction;
        break;
      default:
        numberPage;
        break;
    }
    if (searchData.page) {
      const arrayParamsSearch = search.split('&');
      arrayParamsSearch.splice(
        arrayParamsSearch.indexOf(`page=${searchData.page}`),
        1
      );
      urlComponents = {
        page: numberPage,
      };
      const urlQueryString = combineQueryParams(urlComponents);
      void navigate(
        `/properties/?${arrayParamsSearch.join('&')}&${urlQueryString}`
      );
    } else {
      urlComponents = {
        page: numberPage,
      };
      const urlQueryString = combineQueryParams(urlComponents);
      void navigate(`/properties/?${search}&${urlQueryString}`);
    }
  }

  // ---------- No properties found ---------- //

  if (properties.length === 0) {
    return (
      <Box className={classes.mainContainer}>
        <Typography variant="h6" align="center">
          {`We couldn't find anything that match your criteria.`}
        </Typography>
      </Box>
    );
  }

  // ---------- Some properties found ---------- //

  const shouldShowAdInMiddle =
    properties.length >= AD_APPEAR_IN_MIDDLE_THRESHOLD;
  const propertiesHalfPoint = shouldShowAdInMiddle
    ? Math.floor(properties.length / 2)
    : 0;

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.properties}>
        <Typography>
          <b> {total}</b> Properties
        </Typography>
        <Box className={classes.showDesktop}>
          <SelectRecommended />
        </Box>
      </Box>

      <Box className={classes.propertyCardsCtn}>
        {shouldShowAdInMiddle
          ? getPropertyCards({
              properties,
              propertyListMobileViewMode,
              fromIndex: 0,
              toIndex: propertiesHalfPoint - 1,
            })
          : getPropertyCards({
              properties,
              propertyListMobileViewMode,
              fromIndex: 0,
              toIndex: properties.length - 1,
            })}
      </Box>

      <AdUnitContainer>
        <AdUnit
          adSlot={process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_LIST_SLOT_ID ?? ''}
        />
      </AdUnitContainer>

      {shouldShowAdInMiddle && (
        <Box className={classes.propertyCardsCtn}>
          {getPropertyCards({
            properties,
            propertyListMobileViewMode,
            fromIndex: propertiesHalfPoint,
            toIndex: properties.length - 1,
          })}
        </Box>
      )}

      <Pagination
        page={page}
        itemsPerPage={itemsPerPage}
        total={total}
        handlePagination={handlePagination}
      />
    </Box>
  );
}
