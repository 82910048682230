import * as React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Typography } from '../../../../atoms';

export interface BooleanFilterProps {
  value: boolean | undefined;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function BooleanFilter({
  value,
  label,
  onChange,
}: BooleanFilterProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          size="small"
          disableRipple
          checked={!!value}
          onChange={onChange}
        />
      }
      label={<Typography variant="body2">{label}</Typography>}
    />
  );
}
