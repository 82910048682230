import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    viewMap: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50% ,-50%)',
      '& button': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        fontSize: '1.125rem',
        textTransform: 'initial',
        borderRadius: 0,
      },
      '& button:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  })
);
