import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import fetchProperties, {
  FetchPropertiesProps,
  FetchPropertiesSuccessResponse,
} from './fetchProperties';
import API_ENDPOINTS from '../../endpoints';

interface UseSearchPropertiesProps {
  fetchProps: FetchPropertiesProps;

  queryConfig?: UseQueryOptions<FetchPropertiesSuccessResponse>;
}

export default function useProperties({
  fetchProps,
  queryConfig,
}: UseSearchPropertiesProps) {
  return useQuery<FetchPropertiesSuccessResponse, Error>(
    [API_ENDPOINTS.PROPERTIES, fetchProps],
    () => fetchProperties(fetchProps),
    queryConfig
  );
}
