/**
 * The main purpose of this is optimizing images using Cloudinary's on-the-fly
 * image optimization capability.
 *
 * We deliberately leave out most of Cloudinary's optimization options to focus
 * on the ones we need the most.
 */

export interface GetCloudinaryPublicImageUrlOpts {
  // Transformation options for on-the-fly transformations. See documentation
  // here:
  // https://cloudinary.com/documentation/fetch_remote_images#fetch_url_with_on_the_fly_image_manipulation
  // https://cloudinary.com/documentation/image_transformations#transformation_url_structure
  // https://cloudinary.com/documentation/transformation_reference

  // https://cloudinary.com/documentation/transformation_reference#c_crop_resize
  cropResize?: {
    // https://cloudinary.com/documentation/transformation_reference#c_fit
    // https://cloudinary.com/documentation/transformation_reference#c_limit
    // https://cloudinary.com/documentation/transformation_reference#c_crop
    mode?: 'limit' | 'fit' | 'crop' | undefined;

    // Either provide:
    // - width + height, no aspect ratio
    // - aspect ratio, no width, no height
    // - width, no height, no aspect ratio
    // - height, no width, no aspect ratio
    // Recommended: provide width or height only so Cloudinary automatically
    // scales the rest. This fits in with how we're determining mobile / table /
    // desktop screen size.

    // https://cloudinary.com/documentation/transformation_reference#w_width
    width?: number;
    // https://cloudinary.com/documentation/transformation_reference#h_height
    height?: number;
    // https://cloudinary.com/documentation/transformation_reference#ar_aspect_ratio
    aspectRatio?: number | string;
  };
}

/**
 * Given a public image URL, returns a Cloudinary image URL for on-the-fly image
 * optimization purpose.
 *
 * @param {string} imageUrl - The original URL of the image (must be publicly
 * available)
 * @param {GetCloudinaryPublicImageUrlOpts} opts - Options to be provided to
 * Cloudinary
 *
 * @returns {string} - The Cloudinary URL for the image
 */
export default function getCloudinaryPublicImageUrl(
  imageUrl: string,
  { cropResize }: GetCloudinaryPublicImageUrlOpts = {}
): string {
  // If the Cloudinary ID is not found, we don't use Cloudinary
  if (!process.env.GATSBY_CLOUDINARY_ID) return imageUrl;

  const transformationStrParts: string[] = [];

  if (cropResize) {
    // https://cloudinary.com/documentation/transformation_reference#c_crop_resize
    if (cropResize.mode) transformationStrParts.push(`c_${cropResize.mode}`);

    // https://cloudinary.com/documentation/transformation_reference#w_width
    if (cropResize.width) transformationStrParts.push(`w_${cropResize.width}`);

    // https://cloudinary.com/documentation/transformation_reference#h_height
    if (cropResize.height)
      transformationStrParts.push(`h_${cropResize.height}`);

    // https://cloudinary.com/documentation/transformation_reference#ar_aspect_ratio
    if (cropResize.aspectRatio)
      transformationStrParts.push(`ar_${cropResize.aspectRatio}`);
  }

  // Deliver webp whenever possible
  // https://cloudinary.com/documentation/image_optimization#automatic_format_selection_f_auto
  transformationStrParts.push('f_auto');

  // Deliver compressed quality image
  transformationStrParts.push('q_70');

  let transformationStr = transformationStrParts.join(',');

  // Append a forward slash to join with the final URL
  if (transformationStr) transformationStr += '/';

  return encodeURI(
    `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_ID}/image/fetch/${transformationStr}${imageUrl}`
  );
}
