import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      backgroundColor: 'transparent',
      maxWidth: theme.custom.container1460,
      root: {
        width: '100%',
        maxWidth: theme.spacing(40),
        padding: theme.spacing(2),
      },
    },
    title: {
      marginBottom: 0,
      fontSize: '1.25rem',
      color: theme.palette.gray.contrastText,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    des: {
      fontSize: '1.25rem',
      color: theme.palette.gray.dark,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    mt0: {
      marginTop: 0,
    },
    iconSize: {
      maxWidth: 110,
      flex: '0 0 110px',
      height: 'auto',
      margin: 'auto',
    },
    root: {
      flexGrow: 1,
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        textAlign: 'center',
      },
    },
  })
);
