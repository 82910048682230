import { ScreenType, screenTypeMinWidthThresholds } from '../../../../config';
import { PropertyListMobileViewMode } from '../PropertyListPage';
import { CloudinaryPictureSizeConfig } from '../../../atoms/CloudinaryPicture/CloudinaryPicture';
import { getScreenSizeThresholds } from '../../../../utils/screen';

export function getPropertyImageCloudinaryPictureSizeConfig(
  mobileViewMode: PropertyListMobileViewMode
): CloudinaryPictureSizeConfig {
  const mobileThresholdKey = getScreenSizeThresholds(ScreenType.MOBILE);
  const tabletPortraitThresholdKey = getScreenSizeThresholds(
    ScreenType.TABLET_PORTRAIT
  );
  const tabletLandscapeThresholdKey = getScreenSizeThresholds(
    ScreenType.TABLET_LANDSCAPE
  );

  const config = {
    [tabletPortraitThresholdKey]: {
      height: 400,
    },
    [tabletLandscapeThresholdKey]: {
      // width: 280,
      height: 400,
    },
    [screenTypeMinWidthThresholds[ScreenType.DESKTOP]]: {
      // width: 372,
      height: 400,
    },
  };

  if (mobileViewMode === PropertyListMobileViewMode.NARROW) {
    config[mobileThresholdKey] = {
      // width: 110,
      height: 400,
    };
  } else {
    config[mobileThresholdKey] = {
      // width: 550,
      height: 400,
    };
  }

  return config;
}
