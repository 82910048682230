import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
    },
    tabPanel: {
      padding: '0 !important',
    },
    listTab: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3.5),
    },
    listItem: {
      margin: '0 20px',
      fontSize: '20px',
      color: theme.palette.gray.main,
      textTransform: 'capitalize',
      padding: 0,
      minWidth: 'auto',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(2),
      },
    },
    tabSelected: {
      fontWeight: 'bold',
    },
    center: {
      textAlign: 'center',
    },
    customBtn: {
      backgroundColor: theme.palette.primary.main,
      textTransform: 'initial',
      color: theme.palette.primary.contrastText,
      fontSize: '1.25rem',
      padding: '13px 108px',
      borderRadius: 0,
      [theme.breakpoints.down('sm')]: {
        padding: '13px 38px',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    slick: {
      [theme.breakpoints.up('sm')]: {
        '& .slick-track .slick-slide': {
          height: '100%',
        },
      },
      '& div.slick-track .slick-slide > div': {
        display: 'flex',
      },
      '& .slick-track .slick-slide:last-child $propertyItem': {
        width: 'calc(100vw - 12px)',
      },
    },
    propertyItem: {
      width: '75vw',
      margin: '.75rem',
      maxHeight: 550,
      overflow: 'hidden',
    },
    border: {
      border: '1px solid #cccccc',
      display: 'block',
      height: '100%',
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    analyticsHeading: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
      fontSize: '1.375rem',
      fontWeight: 600,
      lineHeight: 1,
      color: '#1C1C1C',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
        marginBottom: '0.5rem',
      },
    },
    week: {
      fontSize: '1.125rem',
      color: '#1C1C1C',
      '& > b': {
        fontSize: '1.5625rem',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
          fontSize: '1.25rem',
        },
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem',
      },
    },
    desItem: {
      padding: '1px 0',
    },
    widthIcon: {
      minWidth: 30,
    },
    roomIcon: {
      color: theme.palette.primary.main,
    },
    analyticsRoomIcon: {
      color: theme.palette.gray.dark,
      [theme.breakpoints.down('md')]: {
        minWidth: 20,
        '& > svg': {
          fontSize: '1rem',
        },
      },
    },
    trainIcon: {
      color: theme.palette.secondary.main,
    },
    textDes: {
      color: theme.palette.gray.dark,
      '& > span': {
        fontSize: '1.125rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
        },
      },
    },
    analyticsTextDesc: {
      color: theme.palette.gray.light1,
      '& > span': {
        fontWeight: 600,
        fontSize: '1.25rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
        },
      },
    },
    colorLight: {
      color: '#B2B2B2',
    },
    info: {
      padding: '15px 20px 15px 45px',
      [theme.breakpoints.down('md')]: {
        padding: '15px',
      },
    },
    relative: {
      backgroundColor: 'rgba(0, 0, 0, 0.11)',
      position: 'relative',
      height: 280,
      overflow: 'hidden',
      '& img': {
        objectFit: 'fill',
        objectPosition: 'center',
      },
    },
    note: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '7px 0',
      backgroundColor: theme.palette.primary.main,
      fontSize: '1.125rem',
      color: theme.palette.primary.contrastText,
      position: 'absolute',
      width: '100%',
      bottom: 5,
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
      '& > svg': {
        fontSize: '1.25rem',
        marginRight: 5,
        transform: 'rotate(90deg)',
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
        },
      },
    },

    playIcon: {
      width: 64,
      height: 64,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0,0,0,0.5)',
      borderRadius: '50%',
      color: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      pointerEvents: 'none',
      '& svg': {
        fontSize: theme.typography.pxToRem(36),
      },
    },

    analytics: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      [theme.breakpoints.down('md')]: {
        gap: 0,
      },
    },
    analyticItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    analyticText: {
      fontSize: '1.125rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    arrowIcon: {
      color: theme.palette.gray.contrastText,
      transform: 'scale(2.5)',
      fontSize: 14,
      marginRight: 4,
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
      },
    },
    upIcon: {
      color: '#2CA792',
      fontSize: 12,
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
      },
    },
    downIcon: {
      color: theme.palette.primary.main,
      fontSize: 12,
      transform: 'rotate(180deg)',
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
      },
    },
  })
);
