import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from '../../../atoms';

export interface PropertyTagProps {
  text: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyTag: {
      border: `1px solid ${theme.palette.secondary.main}`,
      padding: theme.spacing(0.25, 0.5),
      backgroundColor: '#eaecf4',
      lineHeight: 1.15,
      whiteSpace: 'nowrap',
    },
  })
);

export default function PropertyTag({ text }: PropertyTagProps) {
  const classes = useStyles();

  return (
    <Typography
      className={classes.propertyTag}
      variant="subtitle2"
      color="secondary"
    >
      {text}
    </Typography>
  );
}
