import * as React from 'react';
import { Room } from '@material-ui/icons';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from '../../../atoms';

export interface PriceTextProps {
  addressText: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      color: theme.palette.grey['600'],
    },

    text: {
      lineHeight: 1.15,
    },
  })
);

export default function AddressText({ addressText }: PriceTextProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Room color="inherit" />
      <Typography
        className={classes.text}
        variant={'subtitle1'}
        color="grey"
        colorVariant={600}
      >
        {addressText}
      </Typography>
    </Box>
  );
}
