import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      '& > *': {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
          marginBottom: theme.spacing(2),
        },
      },
    },

    properties: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: theme.spacing(2),
    },

    propertyCardsCtn: {
      display: 'flex',
      flexDirection: 'column',

      gap: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        gap: theme.spacing(2),
      },
    },

    showDesktop: {
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
  })
);

export default useStyles;
