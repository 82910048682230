import { DevelopmentPrimitiveAttributesFilter } from './types';

export type PrimitiveAttributesFilterValueVariant =
  | 'string'
  | 'boolean'
  | 'stringarray';

const primitiveAttributes: Record<
  keyof DevelopmentPrimitiveAttributesFilter,
  PrimitiveAttributesFilterValueVariant
> = {
  attr_concierge: 'boolean',
  attr_parcel: 'boolean',
  attr_onsite_maintenance: 'boolean',
  attr_bike_storage: 'boolean',
  attr_parking: 'boolean',
  attr_lift: 'boolean',
  attr_cctv: 'boolean',
  attr_controlled_access: 'boolean',
  attr_gym: 'boolean',
  attr_pool: 'boolean',
  attr_common_area: 'boolean',
  attr_entertainment_area: 'boolean',
  attr_cinema: 'boolean',
  attr_work_area: 'boolean',
  attr_library: 'boolean',
  attr_wifi: 'boolean',
  attr_social_events: 'boolean',
  attr_roof_terrace: 'boolean',
  attr_communal_garden: 'boolean',
  attr_furnished: 'stringarray',
  attr_smoke_alarm: 'boolean',
  attr_video_door_entry: 'boolean',
  attr_usb_port: 'boolean',
  attr_epc: 'stringarray',
  attr_onsite_management: 'boolean',
  attr_dining_room: 'boolean',
  attr_bar: 'boolean',
  attr_guest_suites: 'boolean',
  attr_heating: 'stringarray',
  attr_hot_water: 'stringarray',
  attr_bathroom_type: 'stringarray',
  attr_swimming_pool: 'boolean',
  fttr_bed_and_mattress: 'boolean',
  fttr_bed_size: 'stringarray',
  fttr_built_in_wardrobe: 'boolean',
  fttr_drawers: 'boolean',
  fttr_sofa: 'boolean',
  fttr_coffee_table: 'boolean',
  fttr_dining_table_and_chairs: 'boolean',
  fttr_tv_stand: 'boolean',
  fttr_tv: 'boolean',
  fttr_open_plan_kitchen: 'boolean',
  fttr_hob_type: 'stringarray',
  fttr_fridge: 'boolean',
  fttr_oven: 'boolean',
  fttr_microwave: 'boolean',
  fttr_dishwasher: 'boolean',
  fttr_washing_machine: 'boolean',
  fttr_dryer: 'boolean',
  fttr_smart_meter: 'boolean',
  fttr_balcony: 'boolean',
  fttr_pet_friendly: 'boolean',
  fttr_student_friendly: 'boolean',
  fttr_family_friendly: 'boolean',
  fttr_armchair: 'boolean',
  short_rent: 'boolean',
  min_term: 'stringarray',
  max_term: 'stringarray',
  deposit_term: 'stringarray',
  notice_period: 'stringarray',
  zero_deposit: 'boolean',
  break_clause: 'stringarray',
};

export default function isPrimitiveAttribute(
  attribute: string
): PrimitiveAttributesFilterValueVariant | undefined {
  return primitiveAttributes[attribute] as
    | PrimitiveAttributesFilterValueVariant
    | undefined;
}
