import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Train } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoSectionNearbyPlacesIcon: {
      color: theme.palette.secondary.main,
      width: '1.5rem',
      height: '1.5rem',
      '& svg': {
        fontSize: '1.5rem',
      },
    },
  })
);

export default function TrainIcon() {
  const classes = useStyles();

  return (
    <Box className={classes.infoSectionNearbyPlacesIcon}>
      <Train color="inherit" />
    </Box>
  );
}
