import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { propertyUrls } from './config';
import { OrgFile } from '../orgs/types';

export interface FetchPropertyThumbnailSuccessResponse {
  data: OrgFile;
}

interface UseFetchPropertyThumbnailProps {
  developmentId: number;
  queryConfig?: UseQueryOptions<FetchPropertyThumbnailSuccessResponse>;
}

export async function fetchPropertyThumbnail(
  url: string
): Promise<FetchPropertyThumbnailSuccessResponse> {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(
      `Could not fetch property images. Reason: ${res.status} - ${res.statusText} - ${errMsg}`
    );
  }

  return res.json() as Promise<FetchPropertyThumbnailSuccessResponse>;
}

export default function useFetchPropertyThumbnail({
  developmentId,
  queryConfig,
}: UseFetchPropertyThumbnailProps) {
  const url = propertyUrls.GET_PROPERTY_THUMBNAIL(developmentId);

  return useQuery<FetchPropertyThumbnailSuccessResponse, Error>(
    [url],
    () => fetchPropertyThumbnail(url),
    queryConfig
  );
}
