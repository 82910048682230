import * as React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Typography } from '../../../../atoms';
import useStyles from './RadiosFilter.styles';

export interface RadiosFilterProps {
  values: Array<{ value: string; label?: string }>;
  selectedValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function RadiosFilter({
  values,
  selectedValue,
  onChange,
}: RadiosFilterProps) {
  const classes = useStyles();

  return (
    <RadioGroup
      className={classes.radioGroup}
      value={selectedValue}
      onChange={onChange}
    >
      {values.map(({ value, label }) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<Radio color="primary" size="small" disableRipple />}
          label={<Typography variant="body2">{label ?? value}</Typography>}
        />
      ))}
    </RadioGroup>
  );
}
