import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { SearchPropertiesSortValueV2 } from '../../../api/property/types';
import { combineQueryParams, parseQueryParamsString } from '../../../utils/url';
import { getUrlSearchString, navigate } from '../../../utils/dom';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 155,
  },
}));

export default function SelectRecommended() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const search = getUrlSearchString(true);
  let searchData: Record<string, string> = {};
  if (search) {
    searchData = parseQueryParamsString(search);
  }
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let urlComponents = {};
    if (searchData.sort) {
      const arrayParamsSearch = search.split('&');
      arrayParamsSearch.splice(
        arrayParamsSearch.indexOf(`sort=${searchData.sort}`),
        1
      );
      urlComponents = {
        sort: event.target.value,
      };
      const urlQueryString = combineQueryParams(urlComponents);
      void navigate(
        `/properties/?${arrayParamsSearch.join('&')}&${urlQueryString}`
      );
    } else {
      urlComponents = {
        sort: event.target.value,
      };
      const urlQueryString = combineQueryParams(urlComponents);
      void navigate(`/properties/?${search}&${urlQueryString}`);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const arrayPropertiesSortValue: [string, string][] = Object.entries(
    SearchPropertiesSortValueV2
  );
  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        defaultValue={searchData.sort ?? 'RECOMMENDED'}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
      >
        {arrayPropertiesSortValue.map(([sortKey, sortLabel]) => (
          <MenuItem key={sortKey} value={sortKey}>
            {sortLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
