export interface PropertyRecentlyViewed {
  developmentId: number;
  src: string;
  title: string;
  price: number;
}

export function addRecentlyViewedProperty(
  propertyRecentlyViewed: PropertyRecentlyViewed
) {
  const properties = getRecentlyViewedProperties();

  const existProperty = properties.findIndex(
    (property) =>
      property.developmentId === propertyRecentlyViewed.developmentId
  );

  if (existProperty >= 0) {
    properties.splice(existProperty, 1);
  }

  if (properties.length >= 10) {
    properties.pop();
  }

  properties.unshift(propertyRecentlyViewed);
  localStorage.setItem('recentlyViewed', JSON.stringify(properties));
}

export function getRecentlyViewedProperties(): PropertyRecentlyViewed[] {
  const properties = localStorage.getItem('recentlyViewed');

  return properties ? JSON.parse(properties) : [];
}
