import api from '../../api';
import { MapPolygon, PropertiesSearchSortType, PropertyV2 } from '../types';
import { PaginationProperties } from '../../utils/types';
import API_ENDPOINTS from '../../endpoints';
import {
  DevelopmentBaseFilter,
  DevelopmentPrimitiveAttributesFilter,
} from './types';

export interface FetchPropertiesProps {
  address: string;

  filters: DevelopmentBaseFilter & {
    // Primitive attributes - these correlate directly to specific fields in the
    // database
    primitiveAttributes?: DevelopmentPrimitiveAttributesFilter;
  };
  sort: PropertiesSearchSortType;

  // A list of development IDs to exclude from the returned results. This is
  // used by the "Similar Properties" search, where the property being searched
  // itself is excluded.
  excludeDevelopmentIds?: number[];

  pagination?: PaginationProperties;
}

export interface FetchPropertiesSuccessResponse {
  data: PropertyV2[];
  meta: {
    total: number; // How many properties were found regardless of pagination properties

    // These are only available if searching by map polygon
    mapPolygonId?: number;
    mapPolygonGeography?: MapPolygon;
  };
}

export default async function fetchProperties(
  props: FetchPropertiesProps
): Promise<FetchPropertiesSuccessResponse> {
  return api.fetch<FetchPropertiesSuccessResponse, FetchPropertiesProps>({
    url: API_ENDPOINTS.PROPERTIES,
    method: 'POST',
    requestJsonBody: props,
  });
}
