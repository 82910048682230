import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      padding: 0,
    },
    whyTitle: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      color: theme.palette.gray.contrastText,
      textAlign: 'center',
      marginBottom: 30,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        marginBottom: 10,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    bgGray: {
      backgroundColor: '#f3f3f3',
      borderTop: `1px solid ${theme.palette.primary.main}`,
    },
  })
);
