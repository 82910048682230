import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },

    tag: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),

      borderRadius: 5,
      opacity: 0.75,
      backgroundColor: '#fff',
    },

    carouselPrevBtn: {
      // When in mobile view, don't display the button
      display: 'none',

      position: 'absolute',
      top: '50%',
      left: 5,
      transform: 'scaleX(-1) translateY(-50%)',
      cursor: 'pointer',

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },

    carouselNextBtn: {
      // When in mobile view, don't display the button
      display: 'none',

      position: 'absolute',
      top: '50%',
      right: 5,
      transform: 'translateY(-50%)',
      cursor: 'pointer',

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },

    justAddedTagContainer: {
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(2),

      display: 'flex',
      alignItems: 'center',
    },

    imgCountTagContainer: {
      position: 'absolute',
      right: theme.spacing(2),
      bottom: theme.spacing(2),

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&p:first-child': {
        paddingRight: theme.spacing(1),
      },
    },

    emptyImg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',

      backgroundColor: theme.palette.grey['300'],
    },

    propertyImg: {
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      cursor: 'pointer',
    },

    show: {
      display: 'flex',
    },

    playIcon: {
      width: 64,
      height: 64,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0,0,0,0.5)',
      borderRadius: '50%',
      color: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      pointerEvents: 'none',
      '& svg': {
        fontSize: theme.typography.pxToRem(36),
      },
    },
  })
);

export default useStyles;
