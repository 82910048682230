export interface ApiFetchProps<RequestBody = unknown> {
  url: string;

  method?: RequestInit['method'];

  requestHeaders?: RequestInit['headers'];
  requestJsonBody?: RequestBody;
}

export interface Api {
  fetch: <Response = unknown, RequestBody = unknown>(
    props: ApiFetchProps<RequestBody>
  ) => Promise<Response>;
}

const api: Api = {
  fetch: async <Response = unknown, RequestBody = unknown>({
    url,
    method,
    requestHeaders,
    requestJsonBody,
  }: ApiFetchProps<RequestBody>): Promise<Response> => {
    // ----- Prepare request ----- //

    const headers = {
      Accept: 'application/json',
      ...requestHeaders,
    };

    const requestInit: RequestInit = {
      headers,
    };

    if (method) {
      requestInit.method = method;
    }

    if (requestJsonBody) {
      if (!(requestHeaders && requestHeaders['Content-Type'])) {
        headers['Content-Type'] = 'application/json';
      }

      requestInit.body = JSON.stringify(requestJsonBody);
    }

    // ----- Execute fetch & return results ----- //

    const fetchResult = await window.fetch(url, requestInit);

    if (!fetchResult.ok) {
      const errMsg = await fetchResult.text();

      throw new Error(
        `${fetchResult.status} - ${fetchResult.statusText}: ${errMsg}`
      );
    }

    return fetchResult.json() as Promise<Response>;
  },
};

export default api;
