import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    recentlyViewed: {
      background: '#ffffff',
      padding: '20px 30px 10px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 15px 10px',
      },
      margin: theme.spacing(3.75, 0),
    },

    recentlyViewedTitle: {
      color: '#464646',
      fontWeight: 'bold',
    },

    recentlyViewedList: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.25),

      color: theme.palette.gray.dark,
    },

    recentlyViewedListItem: {
      display: 'grid',
      grid: 'auto / 96px 1fr',
      gap: theme.spacing(1),

      padding: 0,
    },

    recentlyViewedListItemImg: {
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      cursor: 'pointer',
    },

    emptyImg: {
      width: 96,
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.grey['300'],
    },

    recentlyViewedListItemTextCtn: {
      color: '#b2b2b2',
      padding: theme.spacing(0, 0.25),
      flexGrow: 1,
    },

    recentlyViewedListItemTitle: {
      fontWeight: 'bold',
      color: theme.palette.gray.dark,
    },

    recentlyViewedListItemPrice: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  })
);
