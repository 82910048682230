import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    adUnitContainer: {
      width: '100%',
      height: 150,
      backgroundColor: '#eee',
    },
  })
);

export default function AdUnitContainer({
  children,
}: {
  children?: React.ReactNode;
}) {
  const classes = useStyles();

  return <div className={classes.adUnitContainer}>{children}</div>;
}
