import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ScreenType } from '../../../../config';
import { PropertyListMobileViewMode } from '../PropertyListPage';

const useStyles = makeStyles<
  Theme,
  {
    propertyListMobileViewMode?: PropertyListMobileViewMode;
    screenType?: ScreenType;
  }
>((theme) =>
  createStyles({
    mainCtn: {
      position: 'relative',
      overflow: 'hidden',
    },

    card: ({ propertyListMobileViewMode }) =>
      propertyListMobileViewMode === PropertyListMobileViewMode.NARROW
        ? {
            // Intended to be used by default. This keeps the image on the left-hand
            // side and keeps information on the right-hand side.
            display: 'grid',
            gridTemplateRows: 'auto',
            gridTemplateColumns: '3fr 7fr',
            gridAutoRows: 'min-content',
          }
        : {
            // Intended to be used when in mobile screen and WIDE view mode. This lays
            // the image and the information vertically in a column.
            display: 'flex',
            flexDirection: 'column',
          },

    // ***** PROPERTY PHOTO SECTION ***** //

    propertyPhotoSection: ({ propertyListMobileViewMode }) =>
      propertyListMobileViewMode === PropertyListMobileViewMode.WIDE
        ? {
            height: theme.spacing(30),
            position: 'relative',
            overflow: 'hidden',
          }
        : {
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
          },

    // ***** INFORMATION SECTION ***** //

    // On Safari, the link element has transparent background, creating an
    // overlapping content effect. This style fixes that issue
    safariLinkFix: {
      backgroundColor: '#fff',
    },

    infoSection: {
      display: 'grid',
      grid: 'auto auto / 1fr auto',
      padding: (props) =>
        theme.spacing(props.screenType === ScreenType.MOBILE ? 1 : 2),
      gap: (props) =>
        theme.spacing(props.screenType === ScreenType.MOBILE ? 0.5 : 1),
    },

    infoSectionHeader: {
      gridColumn: '1 / 3',
    },

    infoSectionLeftColumn: {
      alignSelf: 'stretch',
      display: 'flex',
      flexDirection: 'column',
      gap: (props) =>
        theme.spacing(props.screenType === ScreenType.MOBILE ? 0.5 : 1),
      gridColumn: (props) =>
        props.screenType === ScreenType.MOBILE ? '1 / 3' : '1 / 2',
    },

    infoSectionRightColumn: {
      alignSelf: 'stretch',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      gap: (props) =>
        theme.spacing(props.screenType === ScreenType.MOBILE ? 0.5 : 1),
      gridColumn: (props) =>
        props.screenType === ScreenType.MOBILE ? '1 / 3' : '2 / 3',
    },

    infoSectionNameAndPrice: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: (props) =>
        props.screenType === ScreenType.MOBILE ? 'flex-start' : 'center',
      flexDirection: (props) =>
        props.screenType === ScreenType.MOBILE ? 'column' : 'row',
    },

    infoSectionPropertyTags: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(0.5),
    },

    infoSectionPropertyAttributeIcons: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(0.5),
    },

    infoSectionNearbyPlaces: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export default useStyles;
