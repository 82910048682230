import { NearbyPlaceType, PropertyV2 } from '../api/property/types';
import { NearbyPlace } from '../models/nearbyPlaces';

const STATION_NEARBY_PLACE_TYPES: NearbyPlaceType[] = [
  NearbyPlaceType.SUBWAY_STATION,
  NearbyPlaceType.LIGHT_RAIL_STATION,
  NearbyPlaceType.TRAIN_STATION,
];

export function getAddressFromPropertyData(data: Partial<PropertyV2>): string {
  return [data.address, data.local_authority, data.city, data.postcode]
    .filter((address) => !!address)
    .join(', ');
}

export function getNearbyPlacesByPlaceType(nearbyPlaces: NearbyPlace[]) {
  return nearbyPlaces.reduce<Partial<Record<NearbyPlaceType, NearbyPlace[]>>>(
    (obj, nearbyPlace) => {
      if (!obj[nearbyPlace.place_type]) {
        obj[nearbyPlace.place_type] = [];
      }

      (obj[nearbyPlace.place_type] as NearbyPlace[]).push(nearbyPlace);

      return obj;
    },
    {}
  );
}

export function getNearbyPlaceStations(
  nearbyPlacesByPlaceType: Partial<Record<NearbyPlaceType, NearbyPlace[]>>,
  howMany: number
): NearbyPlace[] {
  const nearbyPlaceStations: NearbyPlace[] = [];

  for (const placeType of STATION_NEARBY_PLACE_TYPES) {
    const nearbyPlaces = nearbyPlacesByPlaceType[placeType];

    if (nearbyPlaces && nearbyPlaces.length > 0) {
      nearbyPlaceStations.push(...nearbyPlaces);
    }
  }

  // Doing this essentially removes duplicate stations by their names. Sometimes
  // tube stations and train stations can have the same name (and different
  // place_id). We don't want these to appear together because we are grouping
  // all stations together, thus this "duplicate" removal is necessary.

  const nearbyPlaceStationsByName = nearbyPlaceStations.reduce<
    Record<string, NearbyPlace>
  >((finalObj, place) => {
    finalObj[place.place_data.name] = place;
    return finalObj;
  }, {});

  return Object.values(nearbyPlaceStationsByName)
    .sort(
      (placeA, placeB) =>
        (placeA.place_distance_m ?? 0) - (placeB.place_distance_m ?? 0)
    )
    .slice(0, howMany);
}
