import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertiesSearchFormCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },

    visibleFiltersCtn: {
      display: 'flex',
      gap: theme.spacing(1),
    },

    moreCardCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),

      width: `min(calc(100vw - ${theme.spacing(4)}px), ${theme.spacing(
        100
      )}px)`,
      maxHeight: theme.spacing(50),

      overflowY: 'scroll',
    },

    filterGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },

    filterGroupItems: {
      display: 'grid',
      grid: `auto / repeat(auto-fit, minmax(${theme.spacing(20)}px, 1fr))`,
      columnGap: theme.spacing(1),
      rowGap: theme.spacing(0.5),
    },

    moreCardControlPanelCtn: {
      display: 'flex',
      padding: theme.spacing(0, 1),
      justifyContent: 'flex-end',
      gap: theme.spacing(1),
    },

    resetButtonTextPortion: {
      // Some spacing between itself and the icon
      paddingLeft: theme.spacing(0.5),
    },

    heroSearchFiltersCtn: {
      display: 'grid',
      grid: `1fr / 1fr`,
      gridGap: theme.spacing(5.5),

      [theme.breakpoints.up('md')]: {
        grid: `1fr / repeat(4, 1fr)`,
      },
      '& label': {
        fontSize: '1.25rem',
        color: '#b2b2b2',
      },
    },

    searchBtnCtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },

    btnSearch: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textTransform: 'initial',
      fontSize: '1.125rem',
      paddingLeft: theme.spacing(5.2),
      paddingRight: theme.spacing(5.2),
      borderRadius: 0,
      height: 53,
      '&:hover': {
        backgroundColor: 'red',
      },
    },

    customColorSelect: {
      marginTop: '23px !important',
      '&:before': {
        border: `1px solid ${theme.palette.gray.main} `,
        top: 0,
      },
      '&:hover': {
        '&:before': {
          border: `1px solid ${theme.palette.primary.main} !important`,
        },
      },
      '&:after': {
        top: 0,
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '& > div, & > select': {
        paddingLeft: 15,
        paddingTop: 11,
        paddingBottom: 11,
      },
      '& #maxRent-number': {
        paddingLeft: 15,
        paddingTop: 11,
        paddingBottom: 11,
      },
    },
  })
);

export default useStyles;
