import {
  ScreenType,
  screenTypeMaxWidthThresholds,
  screenTypeMinWidthThresholds,
} from '../config';

/**
 * Given a screen type, return the min and max threshold (in pixels, per our
 * common configuration), connected by a separator (the separator can be
 * optionally specified, but default to '-').
 */
export function getScreenSizeThresholds(
  screenType: ScreenType,
  separator = '-'
) {
  return `${screenTypeMinWidthThresholds[screenType]}${separator}${screenTypeMaxWidthThresholds[screenType]}`;
}
