import * as React from 'react';
import { Card, ClickAwayListener } from '@material-ui/core';
import useStyles from './DropdownPopper.styles';

export interface DropdownPopperProps {
  closePopper: () => void;
  width?: number | string;
  children?: React.ReactNode;
}

export default function DropdownPopper({
  closePopper,
  width,
  children,
}: DropdownPopperProps) {
  const classes = useStyles({ width });

  return (
    <ClickAwayListener onClickAway={closePopper}>
      <Card className={classes.popper}>{children}</Card>
    </ClickAwayListener>
  );
}
