import { FirestoreDate } from '../utils/firestore';
import { ImageData } from './types';

export type EpcRating = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

export type FlatFacilityAttribute = 'fttr_kitchen_type' | 'fttr_balcony';
export type FlatFurnitureAttribute =
  | 'fttr_bed_type'
  | 'fttr_built_in_wardrobe'
  | 'fttr_drawers'
  | 'fttr_sofa'
  | 'fttr_coffee_table'
  | 'fttr_dining_table_and_chairs'
  | 'fttr_armchair';
export type FlatApplianceAttribute =
  | 'fttr_tv'
  | 'fttr_hob_type'
  | 'fttr_fridge'
  | 'fttr_oven'
  | 'fttr_microwave'
  | 'fttr_dishwasher'
  | 'fttr_washing_machine'
  | 'fttr_dryer'
  | 'fttr_smart_meter';
export type FlatFeeAndCostAttribute = 'fttr_short_rent';
export type FlatNeighbourhoodAttribute =
  | 'fttr_pet_friendly'
  | 'fttr_student_friendly'
  | 'fttr_family_friendly';
export type FlatAttribute =
  | FlatFacilityAttribute
  | FlatFurnitureAttribute
  | FlatApplianceAttribute
  | FlatFeeAndCostAttribute
  | FlatNeighbourhoodAttribute;
export type FlatAttributeGroup =
  | 'Facilities'
  | 'Furniture'
  | 'Appliance'
  | 'FeesAndCosts'
  | 'Neighbourhood';
export type DevelopmentFacilityAttribute =
  | 'attr_concierge'
  | 'attr_parcel'
  | 'attr_onsite_maintenance'
  | 'attr_bike_storage'
  | 'attr_parking'
  | 'attr_lift'
  | 'attr_wifi'
  | 'attr_roof_terrace'
  | 'attr_communal_garden'
  | 'attr_furnished'
  | 'attr_epc'
  | 'attr_dining_room'
  | 'attr_bar'
  | 'attr_guest_suites'
  | 'attr_heating'
  | 'attr_hot_water'
  | 'attr_bathroom_type';
export type DevelopmentAmenityAttribute =
  | 'attr_gym'
  | 'attr_pool'
  | 'attr_laundry'
  | 'attr_common_area'
  | 'attr_entertainment_area'
  | 'attr_cinema'
  | 'attr_work_area'
  | 'attr_library'
  | 'attr_social_events'
  | 'attr_usb_port'
  | 'attr_swimming_pool';
export type DevelopmentSecurityAndSafetyAttribute =
  | 'attr_controlled_access'
  | 'attr_cctv'
  | 'attr_smoke_alarm'
  | 'attr_video_door_entry'
  | 'attr_onsite_management';
export type DevelopmentFeeAndCostAttribute =
  | 'min_term'
  | 'max_term'
  | 'deposit_term'
  | 'notice_period'
  | 'zero_deposit';
export type DevelopmentAttribute =
  | DevelopmentFacilityAttribute
  | DevelopmentAmenityAttribute
  | DevelopmentSecurityAndSafetyAttribute
  | DevelopmentFeeAndCostAttribute;
export type DevelopmentAttributeGroup =
  | 'Facilities'
  | 'Amenities'
  | 'FeesAndCosts'
  | 'SecurityAndSafety';

export enum FlatDetailsCategory {
  PRIVATE_STUDIO = 'private_studio',
  PRIVATE_ONE_BEDROOM = 'private_1br',
  PRIVATE_TWO_BEDROOMS = 'private_2br',
  PRIVATE_THREE_BEDROOMS = 'private_3br',
  PRIVATE_FOUR_BEDROOMS = 'private_4br',
  SHARED = 'shared',
  SHARED_ENSUITE = 'shared_ensuite',
}

export type SvgIconV2Name =
  | 'concierge'
  | 'usbport'
  | 'management'
  | 'onsitemanagement'
  | 'flatsize'
  | 'parcel'
  | 'maintain'
  | 'onsitemaintenance'
  | 'bicycle'
  | 'bikestorage'
  | 'car'
  | 'train'
  | 'bus'
  | 'parking'
  | 'lift'
  | 'cctv'
  | 'controlledaccess'
  | 'gym'
  | 'swimmingpool'
  | 'pool'
  | 'library'
  | 'lounge'
  | 'diningroom'
  | 'guestsuites'
  | 'bar'
  | 'entertainment'
  | 'entertainmentarea'
  | 'cinema'
  | 'workingspace'
  | 'workarea'
  | 'wifi'
  | 'socialevents'
  | 'commonarea'
  | 'terrace'
  | 'roofterrace'
  | 'garden'
  | 'communalgarden'
  | 'furnished'
  | 'smokedetector'
  | 'smokealarm'
  | 'videodoorentry'
  | 'epcrating'
  | 'epc'
  | 'heating'
  | 'bathroom'
  | 'bathroomtype'
  | 'hotwater'
  | 'billsincluded'
  | 'includedbills'
  | 'includedbillsarr'
  | 'bedandmattress'
  | 'bedsize'
  | 'bedtype'
  | 'builtinwardrobe'
  | 'wardrobe'
  | 'sofa'
  | 'drawers'
  | 'bedsidetables'
  | 'armchair'
  | 'coffeetable'
  | 'tvstand'
  | 'tv'
  | 'diningtableandchairs'
  | 'kitchentype'
  | 'openplankitchen'
  | 'hobtype'
  | 'fridge'
  | 'fridgenfreezer'
  | 'oven'
  | 'microwave'
  | 'dishwasher'
  | 'washingmachine'
  | 'washer'
  | 'dryer'
  | 'laundry'
  | 'smartmeter'
  | 'balcony'
  | 'petfriendly'
  | 'familyfriendly'
  | 'studentfriendly'
  | 'shortrent'
  | 'deposit'
  | 'minterm'
  | 'maxterm'
  | 'depositterm'
  | 'nodeposit'
  | 'zerodeposit'
  | 'breakclause'
  | 'noticeperiod'
  | 'phone'
  | 'email';

export type CouncilTaxBand =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I';

export enum DistanceType {
  WALKING = 'walking',
  DRIVE = 'drive',
  TRAIN = 'train',
  BUS = 'bus',
}

export interface DistanceToNearestPlace {
  placeName: string;
  distances: Array<{
    type: DistanceType;
    distance?: number;
    timeDistance?: number;
  }>;
}

export interface TagData {
  tagName: string;
  tagText: string;
}

export interface DevelopmentFromApi {
  addedBy: string;
  addedDate: FirestoreDate;
  organization: string;
  flats: string[];
  lastModifiedBy?: string;
  lastModifiedDate?: FirestoreDate;

  // Basic description & location

  title: string;
  description?: string;
  propertyType?: string;
  owner?: string;
  propertyManager?: string;
  completionYear?: number;
  unitsCount?: number;

  address: string;
  postcode?: string;
  lat: number;
  long: number;

  status: 'Complete' | 'Incomplete';

  // Development facilities

  heatingType?: string;
  hotWaterType?: string;
  epcType?: string;
  hasConcierge?: boolean;
  hasOnsiteManager?: boolean;
  hasOnsiteMaintenance?: boolean;
  hasGym?: boolean;
  hasCommonArea?: boolean;
  hasBikeStorage?: boolean;
  hasLift?: boolean;
  hasCctv?: boolean;
  hasGateAccess?: boolean;
  hasSmokeDetector?: boolean;
  includeElectricityBill?: boolean;
  includeWaterBill?: boolean;
  includeInternetBill?: boolean;
  includeCouncilTax?: boolean;

  // Property facilities

  kitchenType?: string;
  bedSizeType?: string;
  hobType?: string;
  hasTv?: boolean;
  hasBed?: boolean;
  isEnsuite?: boolean;
  hasWardrobe?: boolean;
  hasDrawer?: boolean;
  hasSofa?: boolean;
  hasTeaTable?: boolean;
  hasDiningTable?: boolean;
  hasFridge?: boolean;
  hasOven?: boolean;
  hasMicrowave?: boolean;
  hasWashingMachine?: boolean;
  hasDishwasher?: boolean;

  // Living atmosphere

  isPetFriendly?: boolean;
  isFamilyFriendly?: boolean;
  isStudentFriendly?: boolean;
  isNoSmoking?: boolean;
  canShortRent?: boolean;
  canZeroDeposit?: boolean;
  minTermLengthType?: string;
  maxTermLengthType?: string;
  depositLengthType?: string;

  // Legal

  noticePeriod?: string;
  keyLossFee?: string;
  tenantChangeFee?: string;
  latePaymentFee?: string;

  // Images

  imgIds?: string[];
  imgData?: Record<string, ImageData>;

  // Tags

  tags?: TagData[];
}

export interface FlatFromApi {
  addedBy: string;
  addedDate: FirestoreDate;
  organization: string;
  development: string;
  lastModifiedBy?: string;
  lastModifiedDate?: FirestoreDate;

  clientId: string; // Not really used...just a remnant from the property form

  // Details

  title?: string;
  level?: number;
  Sqm?: number;
  maxPax?: number;
  shortTermRent?: number;
  longTermRent?: number;
  bedroomCount?: string;
  furnished?: boolean;
  councilTaxBandType?: CouncilTaxBand;
  availableDate?: FirestoreDate;

  // Images

  imgIds?: string[];
  imgData?: Record<string, ImageData>;
  floorPlanIds: string[];
  floorPlanData?: Record<string, ImageData>;
}
