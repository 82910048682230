import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import getCloudinaryPublicImageUrl from '../../../libs/cloudinary/getCloudinaryImageUrl';

/**
 * The 'key' part of this object should be either:
 * - A single number, which will be interpreted as the min-width in pixels
 * - 2 numbers separated by a dash, which will be interpreted as min-width and
 *   max-width (the min-width comes first) in pixels
 */
export type CloudinaryPictureSizeConfig = Record<
  string,
  { width?: number; height?: number }
>;

export interface CloudinaryPictureProps {
  imgSrc: string;
  imgAlt: string;
  config: CloudinaryPictureSizeConfig;
  pictureProps?: React.ComponentPropsWithoutRef<'picture'>;
  imgProps?: React.ComponentPropsWithoutRef<'img'>;
}

const useStyles = makeStyles(() =>
  createStyles({
    img: {
      objectFit: 'fill',
      objectPosition: 'center',
    },
  })
);

export default function CloudinaryPicture({
  imgSrc,
  imgAlt,
  config,
  pictureProps,
  imgProps,
}: CloudinaryPictureProps) {
  const classes = useStyles();

  const sources = Object.entries(config).map(
    ([mediaThreshold, { width, height }]) => {
      const [minWidthThreshold, maxWidthThreshold] = mediaThreshold.split('-');

      const sourceMedia =
        maxWidthThreshold !== undefined
          ? `(min-width: ${minWidthThreshold}px) and (max-width: ${maxWidthThreshold}px)`
          : `(min-width: ${mediaThreshold}px)`;

      const sourceSrcSet1xUrl = getCloudinaryPublicImageUrl(imgSrc, {
        cropResize: {
          mode: 'fit',
          width,
          height,
        },
      });
      const sourceSrcSet2xUrl = getCloudinaryPublicImageUrl(imgSrc, {
        cropResize: {
          mode: 'fit',
          width: width && width * 2,
          height: height && height * 2,
        },
      });
      const sourceSrcSet = `${sourceSrcSet1xUrl} 1x, ${sourceSrcSet2xUrl} 2x`;

      return (
        <source
          key={mediaThreshold}
          media={sourceMedia}
          srcSet={sourceSrcSet}
        />
      );
    }
  );

  return (
    <picture {...pictureProps}>
      {sources}
      <img className={classes.img} src={imgSrc} alt={imgAlt} {...imgProps} />
    </picture>
  );
}
